import api from "data/APIs";

export const pastEmailProps = {
  basePath: `/${api.get_past_email}`,
  history: {
    length: 6,
    action: "POP",
    location: { pathname: `/${api.get_past_email}`, search: "", hash: "" },
  },
  location: { pathname: `/${api.get_past_email}`, search: "", hash: "" },
  match: {
    path: `/${api.get_past_email}`,
    url: `/${api.get_past_email}`,
    isExact: true,
    params: {},
  },
  resource: `${"past_emails"}`,
  options: { label: `${api.get_past_email}` },
  hasList: true,
  hasEdit: false,
  hasShow: false,
  hasCreate: false,
  syncWithLocation: true,
};

export const draftEmailsProps = {
  basePath: `/${api.fetch_draft_emails}`,
  history: {
    length: 6,
    action: "POP",
    location: { pathname: `/${api.fetch_draft_emails}`, search: "", hash: "" },
  },
  location: { pathname: `/${api.fetch_draft_emails}`, search: "", hash: "" },
  match: {
    path: `/${api.fetch_draft_emails}`,
    url: `/${api.fetch_draft_emails}`,
    isExact: true,
    params: {},
  },
  resource: `${api.fetch_draft_emails}`,
  options: { label: `${api.fetch_draft_emails}` },
  hasList: true,
  hasEdit: false,
  hasShow: false,
  hasCreate: false,
  syncWithLocation: true,
};

export const scheduledEmailsProps = {
  basePath: `/${api.get_past_email}`,
  history: {
    length: 6,
    action: "POP",
    location: { pathname: `/${api.get_past_email}`, search: "", hash: "" },
  },
  location: { pathname: `/${api.get_past_email}`, search: "", hash: "" },
  match: {
    path: `/${api.get_past_email}`,
    url: `/${api.get_past_email}`,
    isExact: true,
    params: {},
  },
  resource: `${"scheduled_emails"}`,
  options: { label: `${api.get_past_email}` },
  hasList: true,
  hasEdit: false,
  hasShow: false,
  hasCreate: false,
  syncWithLocation: true,
};
