import ExlyEmptyState from "features/Common/modules/ExlyEmptyState/ExlyEmptyState";
import React, { FC } from "react";
import { EMAIL_CAMPAIGNS_LEARN_MORE_HREFS } from "../../data";
import { EXLY_EMPTY_STATE_SECONDARY_CTA_VARIANTS } from "features/Common/modules/ExlyEmptyState/constants/ExlyEmptyState.constants";
import { IExlyEmptyState } from "features/Common/modules/ExlyEmptyState/ExlyEmptyState.interfaces";
import useFeatureOnboardingChecker from "common/Components/FeatureOnboarding/useFeatureOnboardingChecker";
import { onboardingFeatureKeys } from "common/Components/FeatureOnboarding/FeatureOnboarding.constants";

const DripCampaignEmptyState: FC<IExlyEmptyState> = (props) => {
  const {
    isOnboardedPrevVal: isFeatureVisited,
    flagLoading: isFeatureFlagLoading,
    handleFeatureOnboardChange,
  } = useFeatureOnboardingChecker({
    featureKey: onboardingFeatureKeys.KNOWLEDGE_BASE.DRIP_CAMPAIGNS,
    // @ts-ignore
    successCallback: (marked) => !marked && handleFeatureOnboardChange(true),
  });

  return (
    <ExlyEmptyState
      isLoading={isFeatureFlagLoading}
      secondaryCtaProps={{
        variant:
          EXLY_EMPTY_STATE_SECONDARY_CTA_VARIANTS.learn_more as keyof typeof EXLY_EMPTY_STATE_SECONDARY_CTA_VARIANTS,
        learnMoreHref: EMAIL_CAMPAIGNS_LEARN_MORE_HREFS.DRIP_CAMPAIGNS,
      }}
      playerProps={{ playing: !isFeatureFlagLoading && !isFeatureVisited }} // autoplay the knowledge base video on the first visit to this feature
      className="mt-4"
      {...props}
    />
  );
};

export default DripCampaignEmptyState;
