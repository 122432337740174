import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(
  (theme) => ({
    modalContentWrapper: {
      padding: ({ isDesktop }) => (isDesktop ? "16px 24px" : "16px"),
      fontFamily: theme?.typography?.fontFamily,
    },
    campaignTitle: {
      fontSize: "16px",
      marginTop: "12px",
      marginBottom: 0,
    },
    campaignSteps: {
      borderCollapse: "separate",
      borderSpacing: "0 14px",
    },
    campaignStep: {
      borderRadius: "14px",
      fontSize: "14px",
      padding: "6px",
      textAlign: "center",
      color: theme?.palette?.basic?.white,
      whiteSpace: "nowrap",
    },
    stepTitle: {
      padding: "0 8px",
    },
    campaignAdded: {
      background: theme?.palette?.primary?.shade_500,
    },
    campaignProcessing: {
      background: theme?.palette?.butterscotch_yellow?.shade_500,
    },
    campaignFailed: {
      background: theme?.palette?.basic?.danger_red,
    },
    campaignCompleted: {
      background: theme?.palette?.fine_pine?.shade_500,
    },
    campaignInsufficientCredits: {
      background: "#FC8542",
    },
  }),
  { name: "CreatorTool" }
);
