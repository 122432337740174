import React from "react";

import { makeStyles } from "@material-ui/core";

import { Shimmer } from "@my-scoot/component-library-legacy";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      border: `1.5px solid ${theme?.palette?.secondary?.shade_100}`,
      borderRadius: "8px",
      padding: "16px 0 16px 12px",
      display: "flex",
      gap: "12px",
      margin: "10px 0",
    },
    templateName: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "17px",
      paddingBottom: "12px",
      borderBottom: `1px solid ${theme?.palette?.secondary?.shade_100}`,
      paddingRight: "8px",
    },
    content: {
      color: theme?.palette?.secondary?.main,
      display: "flex",
      flexDirection: "column",
      gap: "10px",
      flex: 1,
    },
  }),
  { name: "CreatorTool" }
);

const LoadingItem = ({ onClickEdit, ...restProps }) => {
  const classes = useStyles();

  return (
    <div className={classes.root} {...restProps}>
      <Shimmer height="71px" width="103px" />
      <div className={classes.content}>
        <div className={classes.templateName}>
          <Shimmer height="17px" width="99px" borderRadius="8px" />
          {onClickEdit && (
            <Shimmer height="24px" width="24px" borderRadius="50%" />
          )}
        </div>
        <Shimmer height="19px" width="159px" borderRadius="8px" />
        <Shimmer height="17px" width="193px" borderRadius="8px" />
      </div>
    </div>
  );
};

export default LoadingItem;
