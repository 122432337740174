import { Divider } from "@material-ui/core";
import * as React from "react";
import module_style from "../../Style.module.css";
import EnableField from "./EnableField";
import ViewField from "./ViewField";

const DesktopAccordianCard = ({
  communication,
  type,
  category,
  disableEdit,
}) => {
  return (
    <>
      <div className={`${module_style.accordian_row} row no-gutters`}>
        <div className={`col-9`}>
          <div className={`${module_style.accordian_description_title}`}>
            {communication.cdb_type}
          </div>
          <div className={`${module_style.accordian_description_sub_title}`}>
            Time: {communication.trigger}
          </div>
        </div>
        <div className={`col-1`}>
          <div className="float_right">
            <div className={module_style.top_align}>
              <EnableField
                communication={communication}
                type={type}
                disableEdit={disableEdit}
              />
            </div>
          </div>
        </div>
        <div className={`col-2`}>
          <div className="float_right">
            <div className={module_style.top_align_edit}>
              <ViewField
                communication={communication}
                type={type}
                category={category}
                disableEdit={disableEdit}
              />
            </div>
          </div>
        </div>
      </div>

      <div className={module_style.divider}>
        <Divider />
      </div>
    </>
  );
};

export default DesktopAccordianCard;
