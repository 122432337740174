import React from "react";

import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import PollOutlinedIcon from "@material-ui/icons/PollOutlined";
import PauseCircleOutlineOutlinedIcon from "@material-ui/icons/PauseCircleOutlineOutlined";
import { ReactComponent as CopyIcon } from "assets/images/copy.svg";
import RemoveCircleOutlineOutlinedIcon from "@material-ui/icons/RemoveCircleOutlineOutlined";
import style from "./ActiveDrip.module.css";
import { RESOURCE_KEYS, orgPermissions } from "utils/OrgPermissions";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import useDripActions from "./useDripActions";

const useActiveDripActions = ({
  selectedDrip,
  setSelectedDrip,
  closeMobileOptions,
  openPauseModal,
  openEndModal,
}) => {
  const isDesktop = useDesktopMediaQuery();
  const writeDisabled = !orgPermissions.hasFeatureWriteAccess(
    RESOURCE_KEYS.SECTIONS.CUSTOM_MAILS
  );

  const { handleDuplication, handleEdit, handleViewAnalyticsPerEmail } =
    useDripActions({ selectedDrip });

  function handlePauseDrip(record) {
    if (isDesktop) setSelectedDrip(record);
    closeMobileOptions();
    openPauseModal();
  }

  function handleInactivateDrip(record) {
    if (isDesktop) setSelectedDrip(record);
    closeMobileOptions();
    openEndModal();
  }

  const actions = [
    {
      icon: (
        <CreateOutlinedIcon
          className={isDesktop ? style.desktopActionIcon : style.blueAction}
        />
      ),
      onClick: handleEdit,
      label: "Edit drip",
      disabled: writeDisabled,
      className: style.blueButton,
    },
    {
      icon: (
        <PollOutlinedIcon
          className={isDesktop ? style.desktopActionIcon : style.actionIcon}
        />
      ),
      onClick: handleViewAnalyticsPerEmail,
      label: "View analytics per email",
      className: style.blackButton,
    },
    {
      icon: (
        <PauseCircleOutlineOutlinedIcon
          className={isDesktop ? style.desktopActionIcon : style.actionIcon}
        />
      ),
      onClick: handlePauseDrip,
      label: "Pause drip",
      disabled: writeDisabled,
      className: style.blackButton,
    },
    {
      icon: (
        <CopyIcon
          className={isDesktop ? style.desktopActionIcon : style.actionIcon}
        />
      ),
      onClick: handleDuplication,
      label: "Duplicate drip",
      disabled: writeDisabled,
      className: style.blackButton,
    },
    {
      icon: (
        <RemoveCircleOutlineOutlinedIcon
          className={isDesktop ? style.desktopActionIcon : style.redAction}
        />
      ),
      onClick: handleInactivateDrip,
      label: "End drip",
      disabled: writeDisabled,
      className: style.redButton,
    },
  ];

  return {
    writeDisabled,
    handleEdit,
    actions,
  };
};

export default useActiveDripActions;
