import React from "react";
import { is_empty } from "../../../../../utils/validations";
import ExlyLoader from "ui/modules/ExlyLoader";
import constant from "../Constants/constant";
import Accordian from "./Accordian";
import { useDispatch, useSelector } from "react-redux";
import { EXLY_COMMUNICATIONS__REQUEST_CREATOR_CATEGORY } from "../../redux/actions";
import { useStyles } from "../styles";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import EmptyListing from "common/Components/EmptyListing/EmptyListing";
import EmptyListIcon from "assets/vectors/infoModal/Ad_Leads.svg";

const CustomerCreatorList = () => {
  const isDesktop = useDesktopMediaQuery();

  const styles = useStyles(isDesktop);

  const dispatch = useDispatch();
  const categories = useSelector(
    (state) => state.communications.creator_categories
  );
  const fetching_communications = useSelector(
    (state) => state.communications.fetching_communications
  );
  const isEmpty = !fetching_communications && is_empty(categories);

  React.useEffect(() => {
    dispatch({
      type: EXLY_COMMUNICATIONS__REQUEST_CREATOR_CATEGORY,
      payload: { forceUpdate: true },
    });
  }, []);

  return (
    <div className={`dm_font`}>
      {fetching_communications && <ExlyLoader />}
      {isEmpty ? (
        <EmptyListing
          logo={<img src={EmptyListIcon} alt="empty listing" />}
          content_title="No communications to show!"
        />
      ) : (
        <Accordian type={constant.creator_type} className={styles.accordian} />
      )}
    </div>
  );
};

export default CustomerCreatorList;
