import React from "react";

import { Shimmer } from "@my-scoot/component-library-legacy";
import { makeStyles, useMediaQuery } from "@material-ui/core";

import classnames from "classnames";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    border: `1.5px solid ${theme?.palette?.secondary?.shade_100}`,
    borderRadius: "8px",
    marginTop: "10px",
    paddingLeft: "12px",
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "11px 24px 7px 0px",
    borderBottom: `2px solid ${theme?.palette?.secondary?.shade_100}`,
  },
  headerLeftContainer: {
    display: "flex",
    alignItems: "center",
  },
  avatar: {
    marginRight: "8px",
  },
  mailTo: {
    marginRight: "24px",
  },
  content: {
    padding: "13px 24px 18px 36px",
    paddingLeft: ({ isDesktop }) => !isDesktop && "0px",
    display: "flex",
    flexDirection: "column",
    gap: "14px",
  },
  dark: {
    background: theme?.palette?.secondary?.shade_50,
  },
}));

const DraftsLoadingShimmer = () => {
  const isDesktop = useMediaQuery("(min-width: 767px)");
  const classes = useStyles({ isDesktop });

  const CustomShimmer = ({ alternate }) => (
    <div className={classnames(classes.root, alternate && classes.dark)}>
      <div className={classes.header}>
        <div className={classes.headerLeftContainer}>
          <Shimmer
            wrapper_classname={classes.avatar}
            borderRadius="50%"
            height="32px"
            width="32px"
          />
          <Shimmer
            wrapper_classname={classes.mailTo}
            borderRadius="8px"
            height="17px"
            width="151px"
          />
          {isDesktop && (
            <Shimmer borderRadius="8px" height="17px" width="44px" />
          )}
        </div>
        {isDesktop && (
          <Shimmer borderRadius="8px" height="17px" width="151px" />
        )}
      </div>

      <div className={classes.content}>
        <Shimmer
          borderRadius="8px"
          height="14px"
          width={isDesktop ? "372px" : "100%"}
        />
        <Shimmer
          borderRadius="8px"
          height="14px"
          width={isDesktop ? "197px" : "50%"}
        />
      </div>
    </div>
  );

  return (
    <>
      <CustomShimmer />
      <CustomShimmer alternate />
      <CustomShimmer />
      <CustomShimmer alternate />
      <CustomShimmer />
    </>
  );
};

export default DraftsLoadingShimmer;
