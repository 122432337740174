// 3rd party and inbuilt
import React from "react";
import classnames from "classnames";
import AutorenewRoundedIcon from "@material-ui/icons/AutorenewRounded";
import IconButton from "@material-ui/core/IconButton";
import { Tooltip } from "@my-scoot/component-library-legacy";

// style and utils
import style from "./DesktopEmailDrip.module.css";
import { apiKeys } from "../../ActiveDrip/activeDripConfig";
import { emailTooltips } from "ui/pages/EmailDrip/EmailDrip.constants";
import { emailStatsVariant } from "./EmailDripItem.constants";

// Components
import ExlyTooltip from "ui/modules/Tooltip";
import { calculateRate } from "utils/mathUtils";

function DesktopStats({ data, statsVariant = emailStatsVariant.DEFAULT }) {
  const totalEmailsSent = data[apiKeys.totalEmailsSent] || 0;
  const totalOpenCount = data[apiKeys.totalOpenCount] || 0;
  const openRate = calculateRate(totalOpenCount, totalEmailsSent);

  const totalClicksNumber = data[apiKeys.totalClicksNumber] || 0;
  const clickRate = calculateRate(totalClicksNumber, totalEmailsSent);

  const isOpaque = statsVariant === emailStatsVariant.WITH_WITH_OVERLAY;
  const isGreyedOut = statsVariant === emailStatsVariant.GREYED_OUT;
  const hasSentEmail = Boolean(totalEmailsSent);

  return (
    <div className={classnames(style.statsContainer, isOpaque && "opacity-50")}>
      <div className={classnames(style.statItem, isGreyedOut && style.grey)}>
        <div
          className={classnames(
            "mb-2",
            style.statsValue,
            !isGreyedOut && style.color1
          )}
        >
          {totalEmailsSent}
        </div>
        <div>Sends</div>
      </div>
      <div className={classnames(style.statItem, isGreyedOut && style.grey)}>
        <div
          className={classnames(
            "mb-2",
            style.statsValue,
            !isGreyedOut && style.color2
          )}
        >
          {hasSentEmail ? (
            <>
              {openRate} %
              <span className="font_sm ml-1">({totalOpenCount} opens)</span>
            </>
          ) : (
            "-"
          )}
        </div>
        <div>
          Open rate
          <ExlyTooltip
            text={emailTooltips.opens}
            noRightMargin
            infoIconClassName={style.infoIcon}
          />
        </div>
      </div>
      <div className={classnames(style.statItem, isGreyedOut && style.grey)}>
        <div
          className={classnames(
            "mb-2",
            style.statsValue,
            !isGreyedOut && style.color3
          )}
        >
          {hasSentEmail ? (
            <span>
              {clickRate} %
              <span className="font_sm ml-1">({totalClicksNumber} clicks)</span>
            </span>
          ) : (
            "-"
          )}
        </div>
        <div>Click rate</div>
      </div>
      <div className={classnames(style.statItem, isGreyedOut && style.grey)}>
        <div
          className={classnames(
            "mb-2",
            style.statsValue,
            !isGreyedOut && style.color4
          )}
        >
          {hasSentEmail ? data[apiKeys.unsubscribedCount] || 0 : "-"}
        </div>
        <div>Unsubscribed</div>
      </div>
    </div>
  );
}

function DesktopEmailDripItem({
  data,
  fieldConfig,
  showStats,
  statsVariant,
  writeDisabled,
  handleEdit,
  actions = [],
  HeaderIcon = AutorenewRoundedIcon,
  headerFormatter = () => null,
}) {
  return (
    <section className={style.container}>
      <div className={style.header}>
        <HeaderIcon className={style.headIcon} />
        <div className={style.headContentWrap}>
          <div
            className={classnames("ellipsis", style.headContent)}
            onClick={() => {
              if (writeDisabled) return;
              handleEdit(data);
            }}
          >
            {headerFormatter(data)}
          </div>
        </div>

        <div className={style.headerRightNode}>
          <div className={style.desktopActionWrap}>
            {actions.map(({ icon, label, onClick, disabled }) => (
              <IconButton
                className={style.desktopAction}
                onClick={() => onClick(data)}
                key={label}
                disabled={disabled}
              >
                <Tooltip arrow color="primary" placement="top" title={label}>
                  {icon}
                </Tooltip>
              </IconButton>
            ))}
          </div>
        </div>
      </div>
      <div className={style.fieldContainer}>
        {fieldConfig.map(({ label, source, format, className }) => (
          <div className={classnames(style.fieldRow, className)} key={label}>
            <span>{label}: </span>
            <span>{format ? format(data) : data[source] || "-"}</span>
          </div>
        ))}
      </div>
      {showStats && <DesktopStats data={data} statsVariant={statsVariant} />}
    </section>
  );
}

export default DesktopEmailDripItem;
