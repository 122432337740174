import React from "react";
import classnames from "classnames";

export const getStatusChip = ({ type, classes }) => {
  if (type === "draft")
    return (
      <div className={classnames(classes.draftChip, classes.chip)}>draft</div>
    );

  if (type === "scheduled")
    return (
      <div className={classnames(classes.scheduledChip, classes.chip)}>
        Scheduled
      </div>
    );

  return null;
};
