import React, { useState } from "react";
import qs from "query-string";

import EmailCampaignLayout from "../layouts/EmailCampaignLayout";
import { draftDripEmptyStateProps, dripTabValues, tabValues } from "../data";
import DripTabLayout from "../layouts/DripTabLayout";
import { List, useListController } from "react-admin";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import {
  notification_color_keys,
  useNotifications,
  useToggleState,
} from "utils/hooks";
import useDraftDripActions from "./useDraftDripActions";
import ExlyTablePagination from "common/Components/ExlyTable/ExlyTablePagination";
import style from "./DraftDrip.module.css";
import EmailDripItem from "../components/EmailDripItem/EmailDripItem";
import { fieldConfig } from "./draftDripConfig";
import ActionDrawer from "common/Components/ActionDrawer/ActionDrawer";
import EndDripModal from "../modals/EndDripModal/EndDripModal";
import ExlyModal from "common/Components/ExlyModal";
import { launchEmailDrip } from "ui/pages/EmailDrip/EmailDrip.ApiCalls";
import {
  app_pathnames,
  app_route_ids,
  app_route_keys,
} from "constants/urlPaths";
import EmailDripItemLoader from "../components/EmailDripItem/EmailDripItemLoader";
import { getDripHeader } from "../ActiveDrip/activeDripConfig";
import DripCampaignEmptyState from "../components/DripCampaignEmptyState/DripCampaignEmptyState";

const DraftDrip = (props) => {
  const isDesktop = useDesktopMediaQuery();
  const { notify } = useNotifications();
  const { data, loading } = useListController(props);

  const draftDrips = Object.values(data);
  const [selectedDrip, setSelectedDrip] = useState(null);
  const [isMobileOptionsOpen, openMobileOptions, closeMobileOptions] =
    useToggleState(false);
  const [isDeleteModalOpen, openDeleteModal, closeDeleteModal] =
    useToggleState(false);
  const [isLauncModalOpen, openLaunchModal, closeLaunchModal] =
    useToggleState(false);
  const [isLaunching, setIsLaunching] = useState(false);
  const { history } = props;

  const { writeDisabled, handleEdit, actions } = useDraftDripActions({
    setSelectedDrip,
    closeMobileOptions,
    selectedDrip,
    openDeleteModal,
    openLaunchModal,
  });

  async function onLaunch() {
    if (!selectedDrip) return;
    setIsLaunching(true);
    try {
      await launchEmailDrip(selectedDrip?.uuid);
      notify(
        "Drip has successfully been launched!",
        notification_color_keys.success
      );
      closeLaunchModal();
      history.push(`/${app_route_ids[app_route_keys.active_drip]}`);
    } catch {
      const query = { showIncompleteWarningModal: true };
      const searchString = qs.stringify(query);
      // If Drip does not have necessary details for launching, redirect user to fill the details
      history.push({
        search: searchString,
        pathname: app_pathnames[app_route_keys.email_drip_setup](
          selectedDrip?.uuid
        ),
      });
    } finally {
      setIsLaunching(false);
    }
  }

  return (
    <EmailCampaignLayout selectedTab={tabValues.DRIP_CAMPAIGN} {...props}>
      <DripTabLayout selectedTab={dripTabValues.DRAFTS}>
        <List
          {...props}
          component={"div"}
          exporter={false}
          perPage={50}
          pagination={
            <ExlyTablePagination
              classes={{ mobileWrapper: style.mobilePaginationWrapper }}
              borderedTop
            />
          }
          empty={<DripCampaignEmptyState {...draftDripEmptyStateProps} />}
          actions={null}
        >
          <div className={style.listWrap}>
            {loading ? (
              <EmailDripItemLoader showExtraRow={isDesktop} />
            ) : (
              draftDrips.map((dripItem) => (
                <EmailDripItem
                  key={dripItem.uuid}
                  data={dripItem}
                  fieldConfig={fieldConfig}
                  actions={actions}
                  onMobileActionsClick={(record) => {
                    setSelectedDrip(record);
                    openMobileOptions();
                  }}
                  writeDisabled={writeDisabled}
                  handleEdit={handleEdit}
                  headerFormatter={getDripHeader}
                />
              ))
            )}
          </div>
        </List>
        <ActionDrawer
          open={isMobileOptionsOpen}
          onClose={() => {
            setSelectedDrip(null);
            closeMobileOptions();
          }}
          actions={actions}
        />
        <EndDripModal
          isPermananetDeletion
          open={isDeleteModalOpen}
          selectedDrip={selectedDrip}
          onClose={() => {
            setSelectedDrip(null);
            closeDeleteModal();
          }}
        />

        {isLauncModalOpen && (
          <ExlyModal
            open={isLauncModalOpen}
            onClose={closeLaunchModal}
            title="Let’s go!"
            desktopModalWidth="428px"
            primaryBtnText="Launch"
            secondaryBtnText="Close"
            onPrimaryBtnClick={onLaunch}
            className={style.noteContent}
            secondaryBtnProps={{ color: "tertiary", fullWidth: true }}
            showSecondaryBtn
            primaryBtnProps={{ fullWidth: true, loading: isLaunching }}
            footerBtnsPosition="space-between"
            mobile_modal_props={{ footerClassName: style.footer }}
          >
            <span className="font_600 mr-1">
              This action will launch your drip campaign.
            </span>
            Contacts in your selected audience will get subscribed to this drip
            as per the set trigger.
          </ExlyModal>
        )}
      </DripTabLayout>
    </EmailCampaignLayout>
  );
};

export default DraftDrip;
