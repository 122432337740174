import React from "react";
import classnames from "classnames";
import AutorenewRoundedIcon from "@material-ui/icons/AutorenewRounded";
import style from "./MobileEmailDrip.module.css";
import { apiKeys } from "../../ActiveDrip/activeDripConfig";
import ExlyTooltip from "ui/modules/Tooltip";
import { emailTooltips } from "ui/pages/EmailDrip/EmailDrip.constants";

import { ReactComponent as SendIcon } from "assets/vectors/send.svg";
import { ReactComponent as EmailIcon } from "assets/vectors/email3.svg";
import { ReactComponent as ClicksIcon } from "assets/vectors/clicks2.svg";
import { ReactComponent as DeleteEmailIcon } from "assets/vectors/email4.svg";
import HorizontalDotsIconButton from "common/Components/ActionDrawer/HorizontalDotsIconButton";
import { emailStatsVariant } from "./EmailDripItem.constants";
import { calculateRate } from "utils/mathUtils";

function MobileStats({ data, statsVariant = emailStatsVariant.DEFAULT }) {
  const totalEmailsSent = data[apiKeys.totalEmailsSent] || 0;
  const totalOpenCount = data[apiKeys.totalOpenCount] || 0;
  const openRate = calculateRate(totalOpenCount, totalEmailsSent);

  const totalClicksNumber = data[apiKeys.totalClicksNumber] || 0;
  const clickRate = calculateRate(totalClicksNumber, totalEmailsSent);

  const isOpaque = statsVariant === emailStatsVariant.WITH_WITH_OVERLAY;
  const isGreyedOut = statsVariant === emailStatsVariant.GREYED_OUT;
  const hasSentEmail = Boolean(totalEmailsSent);

  return (
    <div className={classnames(style.statsContainer, isOpaque && "opacity-50")}>
      <div className={classnames(style.statItem, isGreyedOut && style.grey)}>
        <SendIcon className={isGreyedOut ? style.greyIcon : ""} />

        <div>
          <div>SENDS</div>
          <div
            className={classnames(
              style.statsValue,
              !isGreyedOut && style.color1
            )}
          >
            {totalEmailsSent}
          </div>
        </div>
      </div>
      <div className={classnames(style.statItem, isGreyedOut && style.grey)}>
        <EmailIcon className={isGreyedOut ? style.greyIcon : ""} />
        <div>
          <div>
            OPEN RATE
            <ExlyTooltip
              text={emailTooltips.opens}
              noRightMargin
              infoIconClassName={style.infoIcon}
            />
          </div>
          <div
            className={classnames(
              style.statsValue,
              !isGreyedOut && style.color2
            )}
          >
            {hasSentEmail ? (
              <>
                {openRate} %
                <span className="font_sm ml-1">({totalOpenCount} opens)</span>
              </>
            ) : (
              "-"
            )}
          </div>
        </div>
      </div>
      <div className={classnames(style.statItem, isGreyedOut && style.grey)}>
        <ClicksIcon className={isGreyedOut ? style.greyIcon : ""} />

        <div>
          <div>CLICK RATE</div>

          <div
            className={classnames(
              style.statsValue,
              !isGreyedOut && style.color3
            )}
          >
            {hasSentEmail ? (
              <span>
                {clickRate} %
                <span className="font_sm ml-1">
                  ({totalClicksNumber} clicks)
                </span>
              </span>
            ) : (
              "-"
            )}
          </div>
        </div>
      </div>
      <div className={classnames(style.statItem, isGreyedOut && style.grey)}>
        <DeleteEmailIcon className={isGreyedOut ? style.greyIcon : ""} />

        <div>
          <div>UNSUBSCRIBED</div>
          <div
            className={classnames(
              style.statsValue,
              !isGreyedOut && style.color4
            )}
          >
            {hasSentEmail ? data[apiKeys.unsubscribedCount] : "-"}
          </div>
        </div>
      </div>
    </div>
  );
}

function MobileEmailDripItem({
  data,
  fieldConfig,
  showStats,
  writeDisabled,
  handleEdit,
  onMobileActionsClick,
  HeaderIcon = AutorenewRoundedIcon,
  MobileActionIcon = HorizontalDotsIconButton,
  headerFormatter = () => null,
  statsVariant,
}) {
  return (
    <section className={style.container}>
      <div className={style.header}>
        <HeaderIcon className={style.headIcon} />

        <div
          className={classnames(
            "ellipsis",
            style.headContent,
            writeDisabled && style.disabled
          )}
          onClick={() => {
            if (writeDisabled) return;
            handleEdit(data);
          }}
        >
          {headerFormatter(data)}
        </div>
        <div className={style.headerRightNode}>
          <MobileActionIcon onClick={() => onMobileActionsClick(data)} />
        </div>
      </div>
      <div className={style.fieldContainer}>
        {fieldConfig.map(
          ({ label, source, format, className, contentClassName }) => (
            <div className={classnames(style.fieldRow, className)} key={label}>
              <span className={style.fieldLabel}>{label}: </span>
              <span className={classnames(style.fieldData, contentClassName)}>
                {(format ? format(data) : data[source]) || "-"}
              </span>
            </div>
          )
        )}
      </div>
      {showStats && <MobileStats data={data} statsVariant={statsVariant} />}
    </section>
  );
}

export default MobileEmailDripItem;
