import React from "react";
import {
  List,
  SimpleList,
  Pagination,
  useListController,
  useRefresh,
} from "react-admin";
import { is_empty } from "../../../../../utils/validations";

import EmailListItem from "../components/EmailListItem/EmailListItem";

import { basicStyles, useStyles } from "./pastEmails.styles";

import { PastEmailsLoadingShimmerCard } from "../components";
import ExlyDeleteModal from "common/Components/ExlyDeleteModal/ExlyDeleteModal";
import EmailCampaignLayout from "../layouts/EmailCampaignLayout";
import { EMAIL_CAMPAIGNS_LEARN_MORE_HREFS, tabValues } from "../data";
import withComponentLibraryTheme from "hocs/withComponentLibraryTheme";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import { RESOURCE_KEYS, orgPermissions } from "utils/OrgPermissions";
import { deleteEmail } from "../Email.helpers";
import { useNotifications } from "utils/hooks";
import { CheckCreditExpiry } from "common/Components/UBP/CheckCreditExpiry";
import { FEATURE_KEYS } from "common/Components/UBP/ubp.constants";
import CustomEmailEmptyState from "../components/CustomEmailEmptyState/CustomEmailEmptyState";

const PastEmails = (props) => {
  const classes = useStyles();
  const isDesktop = useDesktopMediaQuery();
  const { notify } = useNotifications();
  const refresh = useRefresh();

  const hasWriteAccess = orgPermissions.hasFeatureWriteAccess(
    RESOURCE_KEYS.SECTIONS.CUSTOM_MAILS
  );

  const { data, loading } = useListController(props);

  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [selectedEmail, setSelectedEmail] = React.useState(null);

  const ListPagination = (props) => (
    <Pagination rowsPerPageOptions={[]} {...props} />
  );

  const handleDelete = (email) => {
    setSelectedEmail(email);
    setShowDeleteModal(true);
  };

  return (
    <>
      {isDesktop && (
        <CheckCreditExpiry feature_key={FEATURE_KEYS.email_marketing.key} />
      )}
      <EmailCampaignLayout selectedTab={tabValues.PAST_EMAIL} {...props}>
        {loading && <PastEmailsLoadingShimmerCard />}

        {!loading && is_empty(data) && (
          <CustomEmailEmptyState
            title="You haven't sent any mails so far."
            description="Send emails to your leads or customers. View the video to learn more."
            showPrimaryCta
            videoSrc={EMAIL_CAMPAIGNS_LEARN_MORE_HREFS.EMAIL_CAMPAIGNS_VIDEO}
          />
        )}

        {!loading && !is_empty(data) && (
          <List
            style={isDesktop ? {} : basicStyles.listRoot}
            {...props}
            component={"div"}
            exporter={false}
            perPage={50}
            pagination={<ListPagination />}
            actions={null}
          >
            <div>
              <SimpleList
                className={classes.simpleListRoot}
                style={basicStyles.simpleList}
                rowStyle={() => basicStyles.row}
                primaryText={(email, id) => (
                  <EmailListItem
                    id={id}
                    type="past"
                    email={email}
                    key={email.uuid}
                    onDelete={() => handleDelete(email)}
                    hasWriteAccess={hasWriteAccess}
                  />
                )}
                linkType={false}
              />
            </div>
          </List>
        )}

        <ExlyDeleteModal
          open={showDeleteModal}
          onClose={() => setShowDeleteModal(false)}
          title="Are you sure?"
          description={`Deleting this email from past emails. It will still be visible on your emailing plaftform.`}
          onDelete={() =>
            deleteEmail(selectedEmail, "past", notify, () => {
              refresh();
              setShowDeleteModal(false);
            })
          }
        />
      </EmailCampaignLayout>
    </>
  );
};

export default withComponentLibraryTheme(PastEmails);
