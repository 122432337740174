import React from "react";
import classnames from "classnames";
import style from "./MobileEmailDrip.module.css";
import SkeletonDiv from "common/Components/SkeletonDiv";
import { emailStatsVariant } from "./EmailDripItem.constants";

function MobileStatsLoader({ statsVariant }) {
  const isOpaque =
    statsVariant === emailStatsVariant.WITH_WITH_OVERLAY ||
    statsVariant === emailStatsVariant.GREYED_OUT;
  return (
    <div className={classnames(style.statsContainer, isOpaque && "opacity-50")}>
      <div className={style.statItem}>
        <SkeletonDiv
          background="#EDEDED"
          width="38px"
          height="38px"
          borderRadius="10px"
        />
        <div>
          <SkeletonDiv
            background="#EDEDED"
            width="44px"
            height="8px"
            borderRadius="13px"
          />
          <SkeletonDiv
            width="49px"
            height="14px"
            borderRadius="13px"
            marginTop="11px"
          />
        </div>
      </div>
      <div className={style.statItem}>
        <SkeletonDiv
          background="#EDEDED"
          width="38px"
          height="38px"
          borderRadius="10px"
        />

        <div>
          <SkeletonDiv
            background="#EDEDED"
            width="44px"
            height="8px"
            borderRadius="13px"
          />
          <SkeletonDiv
            width="49px"
            height="14px"
            borderRadius="13px"
            marginTop="11px"
          />
        </div>
      </div>
      <div className={style.statItem}>
        <SkeletonDiv
          background="#EDEDED"
          width="38px"
          height="38px"
          borderRadius="10px"
        />

        <div>
          <SkeletonDiv
            background="#EDEDED"
            width="44px"
            height="8px"
            borderRadius="13px"
          />
          <SkeletonDiv
            width="49px"
            height="14px"
            borderRadius="13px"
            marginTop="11px"
          />
        </div>
      </div>
      <div className={style.statItem}>
        <SkeletonDiv
          background="#EDEDED"
          width="38px"
          height="38px"
          borderRadius="10px"
        />

        <div>
          <SkeletonDiv
            background="#EDEDED"
            width="44px"
            height="8px"
            borderRadius="13px"
          />
          <SkeletonDiv
            width="49px"
            height="14px"
            borderRadius="13px"
            marginTop="11px"
          />
        </div>
      </div>
    </div>
  );
}

function MobileDripItemLoader({ showExtraRow, showStats, statsVariant }) {
  return (
    <section className={style.container}>
      <div className={style.header}>
        <SkeletonDiv
          background="#EBEBEB"
          width="24px"
          height="24px"
          borderRadius="50%"
        />
        <div className="flex_grow">
          <SkeletonDiv width="190px" height="14px" />
        </div>
        <SkeletonDiv width="26px" height="14px" background="#D9D9D9" />
      </div>
      <div className={style.fieldContainer}>
        <div className={style.fieldRow}>
          <SkeletonDiv width="104px" height="14px" />
          <SkeletonDiv width="172px" height="9px" background="#ECECEC" />
        </div>
        <div className={style.fieldRow}>
          <SkeletonDiv width="104px" height="14px" />
          <SkeletonDiv width="90px" height="9px" background="#ECECEC" />
        </div>

        <div className={style.fieldRow}>
          <SkeletonDiv width="104px" height="14px" />
          <SkeletonDiv width="24px" height="9px" background="#ECECEC" />
        </div>
        {showExtraRow && (
          <div className={style.fieldRow}>
            <SkeletonDiv width="104px" height="14px" />
            <SkeletonDiv width="33px" height="9px" background="#ECECEC" />
          </div>
        )}
      </div>
      {showStats && <MobileStatsLoader statsVariant={statsVariant} />}
    </section>
  );
}

export default MobileDripItemLoader;
