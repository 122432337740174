import { api, dataProvider } from "data";
import { notification_color_keys } from "utils/hooks";

export const deleteEmail = async (email, type, notify, onClose) => {
  let payload = {};

  if (email?.email_key) {
    payload["email_key"] = [email.email_key];
  }

  if (email?.draft_id) {
    payload["draft_id"] = [email.draft_id];
  }

  try {
    await dataProvider.custom_request(
      type === "draft" ? api.delete_draft_email : api.delete_scheduled_mail,
      "POST",
      {
        ...payload,
      }
    );
    onClose();
  } catch {
    notify("Something went wrong!", notification_color_keys.error);
  }
};

export const fetchPastEmails = async (page) => {
  const { data, status } = await dataProvider.custom_request(
    `${api.get_past_email}`,
    "GET",
    { page }
  );
  if (status !== 200)
    throw new Error(`Error fetching past emails: ${data} ${status}`);
  return data;
};
