import React from "react";
import classnames from "classnames";
import style from "./DesktopEmailDrip.module.css";
import { emailStatsVariant } from "./EmailDripItem.constants";
import SkeletonDiv from "common/Components/SkeletonDiv";

function DesktopStatsLoader({ statsVariant }) {
  const isOpaque =
    statsVariant === emailStatsVariant.WITH_WITH_OVERLAY ||
    statsVariant === emailStatsVariant.GREYED_OUT;
  return (
    <div className={classnames(style.statsContainer, isOpaque && "opacity-50")}>
      <div className={classnames(style.statItem)}>
        <SkeletonDiv width="34px" height="17px" />
        <SkeletonDiv
          background="#EDEDED"
          width="60px"
          height="14px"
          borderRadius="10px"
          marginTop="8px"
        />
      </div>
      <div className={classnames(style.statItem)}>
        <SkeletonDiv width="34px" height="17px" />
        <SkeletonDiv
          background="#EDEDED"
          width="60px"
          height="14px"
          borderRadius="10px"
          marginTop="8px"
        />
      </div>
      <div className={classnames(style.statItem)}>
        <SkeletonDiv width="34px" height="17px" />
        <SkeletonDiv
          background="#EDEDED"
          width="60px"
          height="14px"
          borderRadius="10px"
          marginTop="8px"
        />
      </div>
      <div className={classnames(style.statItem)}>
        <SkeletonDiv width="34px" height="17px" />
        <SkeletonDiv
          background="#EDEDED"
          width="60px"
          height="14px"
          borderRadius="10px"
          marginTop="8px"
        />
      </div>
    </div>
  );
}

function DesktopDripItemLoader({ showStats, statsVariant, showExtraRow }) {
  return (
    <section className={style.container}>
      <div className={classnames(style.header, "border-none")}>
        <SkeletonDiv
          background="#EBEBEB"
          width="32px"
          height="32px"
          borderRadius="50%"
        />
        <div className="flex_grow">
          <SkeletonDiv background="#D9D9D9" width="151px" height="17px" />
        </div>
        <div className={style.headerRightNode}>
          <SkeletonDiv background="#D9D9D9" width="107px" height="17px" />
        </div>
      </div>
      <div className={style.fieldContainer}>
        <div className={style.fieldRow}>
          <SkeletonDiv width="372px" height="14px" />
        </div>
        <div className={style.fieldRow}>
          <SkeletonDiv width="197px" height="14px" />
        </div>
        {showExtraRow && (
          <div className={style.fieldRow}>
            <SkeletonDiv width="197px" height="14px" />
          </div>
        )}
      </div>
      {showStats && <DesktopStatsLoader statsVariant={statsVariant} />}
    </section>
  );
}

export default DesktopDripItemLoader;
