import { app_route_ids, app_route_keys } from "constants/urlPaths";
import { api } from "data";
import DraftDripEmtyIcon from "assets/vectors/empty-states/draft-drip.svg";
import InactiveDripEmtyIcon from "assets/vectors/empty-states/inactive-drip.svg";
import PausedDripEmtyIcon from "assets/vectors/empty-states/paused-drip.svg";

export const EMAIL_TYPES = {
  PAST: "past",
  SCHEDULED: "scheduled",
  DRAFT: "draft",
};

export const CAMPAIGN_TYPES = {
  OLD: 1,
  NEW: 2,
};

export const CAMPAIGN_STEPS = {
  CAMPAIGN_ADDED: 1,
  PROCESSING: 2,
  CAMPAIGN_FAILED: 3,
  CAMPAIGN_COMPLETED: 4,
  INSUFFICIENT_CREDITS: 5,
};

export const CAMPAIGN_DESCRIPTIONS = {
  [CAMPAIGN_STEPS.CAMPAIGN_ADDED]:
    "Campaign has been added to the system for email sending process and can take upto 5-10 minutes to change state to processing after trigger.",
  [CAMPAIGN_STEPS.PROCESSING]:
    "Campaign is picked for collecting and sending mails and can remain in processing state upto 24 hours max.",
  [CAMPAIGN_STEPS.CAMPAIGN_COMPLETED]:
    "Campaign has send all the emails from the system.",
  [CAMPAIGN_STEPS.CAMPAIGN_FAILED]:
    "Campaign mail sending process has failed due to some reason.",
  [CAMPAIGN_STEPS.INSUFFICIENT_CREDITS]:
    "Campaign not sent because there are not enough credits available while creating the trigger.",
};

export const tabValues = {
  PAST_EMAIL: "PAST_EMAIL",
  DRAFT_EMAIL: "DRAFT_EMAIL",
  SCHEDULED_EMAIL: "SCHEDULED_EMAIL",
  DRIP_CAMPAIGN: "DRIP_CAMPAIGN",
};

export const FEATURE_TAB_CONFIG = [
  {
    label: "Email history",
    value: tabValues.PAST_EMAIL,
    path: `/${api.get_past_email}`,
  },
  {
    label: "Drafts",
    value: tabValues.DRAFT_EMAIL,
    path: `/${api.fetch_draft_emails}`,
  },
  {
    label: "Scheduled",
    value: tabValues.SCHEDULED_EMAIL,
    path: `/${app_route_ids[app_route_keys.scheduled_emails]}`,
  },
  {
    label: "Drip campaigns",
    value: tabValues.DRIP_CAMPAIGN,
    path: `/${app_route_ids[app_route_keys.active_drip]}`,
  },
];

export const dripTabValues = {
  ACTIVE: "ACTIVE",
  DRAFTS: "DRAFTS",
  PAUSED: "PAUSED",
  INACTIVE: "INACTIVE",
};

export const DRIP_TAB_CONFIG = [
  {
    label: "Active",
    value: dripTabValues.ACTIVE,
    path: `/${app_route_ids[app_route_keys.active_drip]}`,
  },
  {
    label: "Drafts",
    value: dripTabValues.DRAFTS,
    path: `/${app_route_ids[app_route_keys.draft_drip]}`,
  },
  {
    label: "Paused",
    value: dripTabValues.PAUSED,
    path: `/${app_route_ids[app_route_keys.paused_drip]}`,
  },
  {
    label: "Inactive",
    value: dripTabValues.INACTIVE,
    path: `/${app_route_ids[app_route_keys.inactive_drip]}`,
  },
];

export const COMPOSE_OPTIONS_MODAL_OPTIONS = {
  EMAIL_BROADCAST: "EMAIL_BROADCAST",
  DRIP_CAMPAIGN: "DRIP_CAMPAIGN",
};

export const COMPOSE_OPTIONS_MODAL_STAGES = {
  SELECT_OPTION_STAGE: "SELECT_OPTION_STAGE",
  SELECT_TITLE_STAGE: "SELECT_TITLE_STAGE",
};

export const activeDripemptyStateProps = {
  title: "No campaigns out yet.",
  description: "All your launched drip campaigns will appear here.",
};

export const draftDripEmptyStateProps = {
  title: "No drafts yet.",
  description: "All your draft campaigns will appear here.",
  imgSrc: DraftDripEmtyIcon,
};

export const inactiveEmptyStateProps = {
  title: "No inactive campaigns.",
  description: "All your ended drip campaigns will appear here.",
  imgSrc: InactiveDripEmtyIcon,
};

export const pausedEmptyStateProps = {
  title: "No pauses yet.",
  description: "All your paused drip campaigns will appear here.",
  imgSrc: PausedDripEmtyIcon,
};

export const EMAIL_CAMPAIGNS_LEARN_MORE_HREFS = {
  EMAIL_CAMPAIGNS:
    "https://support.exlyapp.com/support/solutions/folders/84000349456",
  DRIP_CAMPAIGNS:
    "https://support.exlyapp.com/support/solutions/folders/84000349456",
  DRIP_CAMPAIGNS_VIDEO: "https://www.youtube-nocookie.com/embed/Nk5DplHuVP0",
  EMAIL_CAMPAIGNS_VIDEO: "https://www.youtube-nocookie.com/embed/of0Xp_it9ZA",
};
