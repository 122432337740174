import React from "react";

import DeleteRoundedIcon from "@material-ui/icons/DeleteRounded";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import { ReactComponent as CopyIcon } from "assets/images/copy.svg";
import { ReactComponent as LaunchIcon } from "assets/vectors/launch.svg";

import dripModuleStyle from "../ActiveDrip/ActiveDrip.module.css";
import { RESOURCE_KEYS, orgPermissions } from "utils/OrgPermissions";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import useDripActions from "../ActiveDrip/useDripActions";

const useDraftDripActions = ({
  selectedDrip,
  setSelectedDrip,
  openDeleteModal,
  closeMobileOptions,
  openLaunchModal,
}) => {
  const isDesktop = useDesktopMediaQuery();
  const writeDisabled = !orgPermissions.hasFeatureWriteAccess(
    RESOURCE_KEYS.SECTIONS.CUSTOM_MAILS
  );
  const { handleDuplication, handleEdit } = useDripActions({
    selectedDrip,
    isDraftListing: true,
  });

  function handleDeleteDrip(record) {
    if (isDesktop) setSelectedDrip(record);
    else closeMobileOptions();
    openDeleteModal();
  }

  function handleLaunchDrip(record) {
    if (isDesktop) setSelectedDrip(record);
    else closeMobileOptions();
    openLaunchModal();
  }

  const actions = [
    {
      icon: (
        <CreateOutlinedIcon
          className={
            isDesktop
              ? dripModuleStyle.desktopActionIcon
              : dripModuleStyle.blueAction
          }
        />
      ),
      onClick: handleEdit,
      label: "Edit drip",
      disabled: writeDisabled,
      className: dripModuleStyle.blueButton,
    },
    {
      icon: (
        <LaunchIcon
          className={
            isDesktop
              ? dripModuleStyle.desktopActionIcon
              : dripModuleStyle.actionIcon
          }
        />
      ),
      onClick: handleLaunchDrip,
      label: "Launch drip",
      disabled: writeDisabled,
      className: dripModuleStyle.blackButton,
    },
    {
      icon: (
        <CopyIcon
          className={
            isDesktop
              ? dripModuleStyle.desktopActionIcon
              : dripModuleStyle.actionIcon
          }
        />
      ),
      onClick: handleDuplication,
      label: "Duplicate drip",
      disabled: writeDisabled,
      className: dripModuleStyle.blackButton,
    },
    {
      icon: (
        <DeleteRoundedIcon
          className={
            isDesktop
              ? dripModuleStyle.desktopActionIcon
              : dripModuleStyle.redAction
          }
        />
      ),
      onClick: handleDeleteDrip,
      label: "Delete drip",
      disabled: writeDisabled,
      className: dripModuleStyle.redButton,
    },
  ];

  return {
    writeDisabled,
    handleEdit,
    actions,
  };
};

export default useDraftDripActions;
