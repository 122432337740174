import { EMAIL_DRIP_STATUS } from "ui/pages/EmailDrip/EmailDrip.constants";

export const emailStatsVariant = {
  DEFAULT: "DEFAULT",
  WITH_WITH_OVERLAY: "WITH_WITH_OVERLAY", // opacting - 0.5
  GREYED_OUT: "GREYED_OUT", // colors of svg's and text grey
};

export const emailLoaderProps = (isDesktop) => ({
  [EMAIL_DRIP_STATUS.ACTIVE]: {
    showStats: true,
    showExtraRow: !isDesktop,
  },
  [EMAIL_DRIP_STATUS.DRAFT]: {
    showExtraRow: isDesktop,
  },
  [EMAIL_DRIP_STATUS.PAUSED]: {
    showStats: true,
    statsVariant: emailStatsVariant.WITH_WITH_OVERLAY,
    showExtraRow: !isDesktop,
  },
  [EMAIL_DRIP_STATUS.INACTIVE]: {
    showStats: true,
    statsVariant: emailStatsVariant.GREYED_OUT,
    showExtraRow: !isDesktop,
  },
});
