import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
  accordion: {
    border: "1.5px solid #E7E6E4",
    boxShadow: "rgb(14 13 12 / 14%) 0px 3px 0px",
    borderRadius: "11px",
    minHeight: "73px",
    padding: "9px 0px 0px 3px",
  },

  accordianSummaryRoot: {
    minHeight: "63px",
  },

  loader: {
    backgroundColor: "#ffffff",
    padding: 0,
    overflow: "visible",
    minWidth: "600px",
  },
}));
