import { apiKeys } from "../ActiveDrip/activeDripConfig";

export const fieldConfig = [
  {
    label: "Trigger",
    source: apiKeys.trigger,
    format: (data) =>
      `${data[apiKeys.subscribeTriggerCount]} subscribe, ${
        data[apiKeys.unsubscribeTriggerCount]
      } unsubscribe`,
  },
  {
    label: "Emails",
    source: apiKeys.emails,
  },
];
