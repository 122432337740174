import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import style from "./EndDripModal.module.css";
import ExlyModal from "common/Components/ExlyModal";
import { notification_color_keys, useNotifications } from "utils/hooks";
import { app_route_ids, app_route_keys } from "constants/urlPaths";
import { useRefresh } from "react-admin";
import {
  deleteEmailDrip,
  endEmailDrip,
} from "ui/pages/EmailDrip/EmailDrip.ApiCalls";
import { inactivateLabels, permanentDeletionLabels } from "./EndDrip.constants";

function DeleteModal({
  open,
  onClose,
  onDelete,
  successCallback = () => {},
  children,
}) {
  const [ending, setEnding] = useState(false);
  const { notify } = useNotifications();

  const handleEnd = async () => {
    if (ending) return;
    setEnding(true);

    try {
      await onDelete();
      onClose();
      successCallback();
    } catch (err) {
      console.log(err, "Error deleting drip");
      notify(
        "Failed to delete, please try again.",
        notification_color_keys.error
      );
    } finally {
      setEnding(false);
    }
  };

  return (
    <ExlyModal
      open={open}
      onClose={onClose}
      title="Are you sure?"
      desktopModalWidth="428px"
      padded={false}
      className={style.container}
      primaryBtnText="Delete"
      primaryBtnProps={{ color: "danger", fullWidth: true, loading: ending }}
      onPrimaryBtnClick={handleEnd}
      secondaryBtnText="Close"
      secondaryBtnProps={{
        color: "tertiary",
        fullWidth: true,
      }}
      footerBtnsPosition="space-between"
      showSecondaryBtn
      mobile_modal_props={{ footerClassName: style.footer }}
    >
      {children}
    </ExlyModal>
  );
}

function EndDripModal({ isPermananetDeletion, selectedDrip, ...rest }) {
  const history = useHistory();
  const refresh = useRefresh();
  const { notify } = useNotifications();
  const label = isPermananetDeletion
    ? permanentDeletionLabels
    : inactivateLabels;

  const successCallback = () => {
    if (isPermananetDeletion) {
      // permanent deletion happens in draft and inactive listing so just refetch the current listing
      refresh();
    } else {
      // we deactivate the listing and redirect to inactive drips
      history.push(`/${app_route_ids[app_route_keys.inactive_drip]}`);
    }
    notify(label.successMessage, notification_color_keys.success);
  };

  const handleDelete = isPermananetDeletion ? deleteEmailDrip : endEmailDrip;
  const onDelete = () => handleDelete(selectedDrip?.uuid);

  return (
    <DeleteModal
      successCallback={successCallback}
      onDelete={onDelete}
      {...rest}
    >
      {label.content}
    </DeleteModal>
  );
}

export default EndDripModal;
