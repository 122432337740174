import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      position: ({ isDesktop }) => !isDesktop && "fixed",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      zIndex: ({ isDesktop }) => !isDesktop && 9999,
      overflow: ({ isDesktop }) => !isDesktop && "scroll",
      marginBottom: ({ isDesktop }) => !isDesktop && 0,
      background: theme?.palette?.basic?.white,
      fontFamily: theme?.typography?.fontFamily,
    },
    tabsRoot: {
      background: theme?.palette?.basic?.white,
      zIndex: 1,
    },
    headerRoot: {
      boxSizing: "border-box",
      position: ({ isDesktop }) => !isDesktop && "sticky",
      top: 0,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      borderBottom: ({ isDesktop }) =>
        !isDesktop && `2px solid ${theme?.palette?.secondary?.shade_100}`,
      padding: ({ isDesktop }) => !isDesktop && "18px 16px",
      background: theme?.palette?.basic?.white,
      zIndex: ({ isDesktop }) => !isDesktop && 9999,
    },
    title: {
      fontSize: "18px",
      fontWeight: 700,
      color: theme?.palette?.secondary?.main,
    },
    headerRightContainer: {
      display: "flex",
      alignItems: "center",
      gap: "32px",
    },
    learnMore: {
      color: theme?.palette?.primary?.main,
      fontSize: "16px",
      fontWeight: 500,
      textDecoration: "underline",
      cursor: "pointer",
    },
    backBtn: {
      padding: 0,
      color: theme?.palette?.primary?.main,
    },
    titleContainer: {
      display: "flex",
      gap: "16px",
      alignItems: "center",
    },
    tabsWrapperScroller: {
      borderBottom: `2px solid ${theme?.palette?.secondary?.shade_100}`,
      margin: "-2px -16px 0 -16px",
    },
    refreshBtnWrapper: {
      textAlign: "right",
      paddingTop: "10px",
      "& button": {
        padding: 0,
      },
    },
    stickyButton: {
      width: "100%",
      position: "fixed",
      bottom: 0,
      background: theme?.palette?.basic?.white,
      borderTop: `2px solid ${theme?.palette?.secondary?.shade_100}`,
      margin: "0px",
      padding: "12px 16px",
    },
    mb_68: {
      marginBottom: "68px",
    },
    listEmptyStateWrap: {
      padding: ({ isDesktop }) =>
        isDesktop
          ? "36.79px 0 0 74px !important"
          : "36.79px 0 0 17px !important",
    },
  }),
  { name: "CreatorTool" }
);
