import * as React from "react";
import module_style from "../../Style.module.css";
import { is_empty } from "utils/validations";
import constant from "../Constants/constant";
import DesktopAccordianCard from "./DesktopAccordianCard";
import { useStyles } from "../styles";

import MobileAccordianCard from "./MobileAccordianCard";
import { useDispatch, useSelector } from "react-redux";
import { Accordion, Avatar } from "@my-scoot/component-library-legacy";
import {
  EXLY_COMMUNICATIONS__REQUEST_USER_COMMUNICATIONS,
  EXLY_COMMUNICATIONS__REQUEST_CREATOR_COMMUNICATIONS,
} from "../../redux/actions";
import ExlyLoader from "ui/modules/ExlyLoader";

import { ReactComponent as Invoices } from "../../../../../assets/images/Invoices.svg";
import { ReactComponent as PurchaseConfirmation } from "../../../../../assets/images/purchase_confirmation.svg";
import { ReactComponent as Reminders } from "../../../../../assets/images/Reminders.svg";
import { ReactComponent as Updates } from "../../../../../assets/images/Updates.svg";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import { orgPermissions } from "utils/OrgPermissions";

const imageDisplay = (heading) => {
  switch (heading) {
    case constant.purchase_confirmation:
      return <PurchaseConfirmation />;
    case constant.reminders:
      return <Reminders />;
    case constant.updates:
      return <Updates />;
    default:
      return <Invoices />;
  }
};

const Accordian = ({ type }) => {
  const dispatch = useDispatch();
  const is_desktop = useDesktopMediaQuery();

  const styles = useStyles(is_desktop);

  const categories = useSelector((state) =>
    type === constant.user_type
      ? state.communications.user_categories
      : state.communications.creator_categories
  );
  const fetching_communications = useSelector(
    (state) => state.communications.fetching_communications
  );

  if (is_empty(categories)) return <></>;

  const getCategoryComms = async (key) => {
    dispatch({
      type:
        type === constant.user_type
          ? EXLY_COMMUNICATIONS__REQUEST_USER_COMMUNICATIONS
          : EXLY_COMMUNICATIONS__REQUEST_CREATOR_COMMUNICATIONS,
      key: key,
    });
  };

  return (
    <div>
      {Object.keys(categories).map((item) => {
        const category = categories[item];
        const hasFeatureWriteAccess = orgPermissions.hasFeatureWriteAccess(
          category.comm_key
        );

        return (
          <div className={`${module_style.top_margin_20}`} key={category.key}>
            <Accordion
              rootClassName={styles.accordion}
              summaryClassName={styles.accordianSummaryRoot}
              onChange={(_, expanded) => {
                if (expanded && is_empty(category.list)) {
                  getCategoryComms(category.key);
                }
              }}
              accordionSummaryContent={
                <div className={module_style.flex_display}>
                  <div className={module_style.top_margin_5}>
                    <Avatar icon={imageDisplay(category.heading)} />
                  </div>
                  <div className={module_style.left_padding_10}>
                    <div className={`${module_style.accordian_header_title}`}>
                      {category.heading}
                    </div>
                    <div
                      className={`${module_style.accordian_header_sub_title}`}
                    >
                      {category.sub_heading}
                    </div>
                  </div>
                </div>
              }
              expandIconPosition="end"
            >
              {fetching_communications && <ExlyLoader />}
              {!is_empty(category?.list) &&
                category.list.map((value, i) => {
                  if (is_desktop)
                    return (
                      <DesktopAccordianCard
                        category={item}
                        communication={value}
                        type={type}
                        disableEdit={!hasFeatureWriteAccess}
                      />
                    );
                  return (
                    // Asked backend to add key, but since comms to creator are sensitive, hence change might take time, talked on 16 December 2022, cofirm again if need be
                    // eslint-disable-next-line react/jsx-key
                    <MobileAccordianCard
                      // eslint-disable-next-line react/no-array-index-key
                      key={i}
                      category={item}
                      communication={value}
                      type={type}
                      disableEdit={!hasFeatureWriteAccess}
                    />
                  );
                })}
            </Accordion>
          </div>
        );
      })}
    </div>
  );
};

export default Accordian;
