import React from "react";

import Backdrop from "@material-ui/core/Backdrop";

import { MobileModal, Modal } from "@my-scoot/component-library-legacy";
import constants from "constants/constants";
import classnames from "classnames";
import { useStyles } from "./index.styles";
import { CAMPAIGN_DESCRIPTIONS, CAMPAIGN_STEPS } from "../../data";
import { campaignStepsData } from "../../components/EmailListItem/utils";

const LearnMoreModal = ({ open, onClose }) => {
  const isDesktop = window.screen.width > constants.mobile_width;
  const classes = useStyles({ isDesktop });

  const content = (
    <div>
      Send emails to your leads or customers. Share details about upcoming
      events, publish a newsletter, provide an important update or just send a
      greeting.
      <h2 className={classes.campaignTitle}>Campaign steps followed:</h2>
      <table className={classes.campaignSteps}>
        <tr>
          <td>1.</td>
          <td className={classes.stepTitle}>
            <strong
              className={classnames(
                classes.campaignStep,
                classes.campaignAdded
              )}
            >
              {campaignStepsData[CAMPAIGN_STEPS.CAMPAIGN_ADDED].label}
            </strong>
          </td>
          <td>{CAMPAIGN_DESCRIPTIONS[CAMPAIGN_STEPS.CAMPAIGN_ADDED]}</td>
        </tr>
        <tr>
          <td>2.</td>
          <td className={classes.stepTitle}>
            <strong
              className={classnames(
                classes.campaignStep,
                classes.campaignProcessing
              )}
            >
              {campaignStepsData[CAMPAIGN_STEPS.PROCESSING].label}
            </strong>
          </td>
          <td>{CAMPAIGN_DESCRIPTIONS[CAMPAIGN_STEPS.PROCESSING]}</td>
        </tr>
        <tr>
          <td>3.</td>
          <td className={classes.stepTitle}>
            <strong
              className={classnames(
                classes.campaignStep,
                classes.campaignCompleted
              )}
            >
              {campaignStepsData[CAMPAIGN_STEPS.CAMPAIGN_COMPLETED].label}
            </strong>
          </td>
          <td>{CAMPAIGN_DESCRIPTIONS[CAMPAIGN_STEPS.CAMPAIGN_COMPLETED]}</td>
        </tr>
        <tr>
          <td>4.</td>
          <td className={classes.stepTitle}>
            <strong
              className={classnames(
                classes.campaignStep,
                classes.campaignFailed
              )}
            >
              {campaignStepsData[CAMPAIGN_STEPS.CAMPAIGN_FAILED].label}
            </strong>
          </td>
          <td>{CAMPAIGN_DESCRIPTIONS[CAMPAIGN_STEPS.CAMPAIGN_FAILED]}</td>
        </tr>
        <tr>
          <td>5.</td>
          <td className={classes.stepTitle}>
            <strong
              className={classnames(
                classes.campaignStep,
                classes.campaignInsufficientCredits
              )}
            >
              {campaignStepsData[CAMPAIGN_STEPS.INSUFFICIENT_CREDITS].label}
            </strong>
          </td>
          <td>{CAMPAIGN_DESCRIPTIONS[CAMPAIGN_STEPS.INSUFFICIENT_CREDITS]}</td>
        </tr>
      </table>
    </div>
  );

  return isDesktop ? (
    <Modal
      dialogContentClassName={classes.modalContentWrapper}
      open={open}
      onClose={onClose}
      title="Custom Emails"
      customFooter={<></>}
    >
      {content}
    </Modal>
  ) : (
    <MobileModal
      open={open}
      onClose={onClose}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      header="Custom Emails"
      customFooter={<></>}
    >
      <div className={classes.modalContentWrapper}>{content}</div>
    </MobileModal>
  );
};

export default LearnMoreModal;
