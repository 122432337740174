import React from "react";

import { MobilePopover } from "@my-scoot/component-library-legacy";

import api from "data/APIs";
import dataProvider from "data/dataProvider";

import DesktopRow from "./components/DesktopRow/DesktopRow";
import MobileRow from "./components/MobileRow/MobileRow";

import constants from "constants/constants";
import { SECTION_TYPES } from "../../ComposeEmail/data";
import { CAMPAIGN_TYPES, EMAIL_TYPES } from "../../data";
import { useStyles } from "./EmailListItem.styles";
import { getTime, trimRecipientLabels } from "./utils";
import { getRecipientName } from "../../ComposeEmail/modules/RecipientsSelector/RecipientsSelector.utils";
import { RECIPIENT_KEYS } from "../../ComposeEmail/modules/RecipientsSelector/RecipientsSelector.constants";
import { others } from "features/Crm/Crm.constants";
import { isInstallmentsPlanType } from "schedule-v2/commonPages/Components/PaymentPlanWithInstallments/utils/PaymentPlanWithInstallments.utils";
import { is_empty } from "utils/validations";

const handleCompose = () => {
  window.location.href = `${window.location.origin}/#/ComposeEmail`;
};

const EmailListItem = ({ id, email, type, onDelete, hasWriteAccess }) => {
  const isDesktop = window.screen.width > constants.mobile_width;

  const classes = useStyles({ isDesktop });

  const [openModal, setOpenModal] = React.useState(false);
  const [recepients, setRecepients] = React.useState(null);

  React.useEffect(() => {
    if (type != EMAIL_TYPES.DRAFT) fetchRecepients();
  }, []);

  const fetchRecepients = () => {
    dataProvider
      .custom_request(api.get_recepients, "GET", {
        email_key: email.email_key || email.draft_id,
      })
      .then((response) => {
        if (response.data?.email_recipients) {
          let localRecepients = [];

          response.data.email_recipients.forEach((obj) => {
            if (obj.key === "extra") {
              obj.emails.map((email) => localRecepients.push(email.email));
            } else {
              localRecepients.push(obj.display_name);
            }
          });

          setRecepients(localRecepients);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleViewEdit = () => {
    const { campaign_type } = email;
    let temp = email;
    temp.view_only = [EMAIL_TYPES.PAST, EMAIL_TYPES.SCHEDULED].includes(type);
    if (campaign_type === CAMPAIGN_TYPES.OLD)
      temp.old_recipients_list = recepients;
    sessionStorage.setItem("draft_data", JSON.stringify(temp));
    handleCompose();
  };

  const handleDuplicate = () => {
    let emailInfo = email;
    emailInfo["duplicate"] = true;
    sessionStorage.setItem("draft_data", JSON.stringify(emailInfo));
    handleCompose();
  };

  const getRecepients = () => {
    const { campaign_type, recipient_list, draft_recipients } = email;

    if (campaign_type === CAMPAIGN_TYPES.NEW) {
      return trimRecipientLabels(
        recipient_list
          ?.slice(0, 2) // showing only 2 recipients label on card, rest are collapsed
          .map(
            ({
              name,
              email_user_type,
              [RECIPIENT_KEYS.sub_section_type]: subSectionType,
              [RECIPIENT_KEYS.payment_plan_type]: paymentPlanType,
            }) =>
              email_user_type === SECTION_TYPES.EXTRAS ||
              email_user_type === SECTION_TYPES.BULK_ACTION_RECIPIENT
                ? ` ${others}`
                : ` ${getRecipientName({
                    displayName: name,
                    subSectionType,
                    isAllCustomers:
                      isInstallmentsPlanType(paymentPlanType) &&
                      is_empty(subSectionType),
                  })}`
          ),
        recipient_list?.length,
        "more"
      );
    }

    if (type === EMAIL_TYPES.DRAFT) {
      return trimRecipientLabels(
        draft_recipients
          ?.slice(0, 2) // showing only 2 recipients label on card, rest are collapsed
          .map(({ display_name, email_user_type }) =>
            email_user_type === SECTION_TYPES.EXTRAS ||
            email_user_type === SECTION_TYPES.BULK_ACTION_RECIPIENT
              ? ` ${others}`
              : ` ${display_name}`
          ),
        draft_recipients?.length,
        "more"
      );
    }

    return trimRecipientLabels(
      recepients?.slice(0, 2), // showing only 2 recipients label on card, rest are collapsed
      recepients?.length,
      "recipients"
    );
  };

  const popoverOptions = [
    {
      label: type === EMAIL_TYPES.DRAFT ? "Edit email" : "View email",
      onClick: handleViewEdit,
    },
    {
      label: "Duplicate this email",
      onClick: handleDuplicate,
    },
    {
      label: "Delete",
      onClick: onDelete,
    },
  ];

  const alternate = Number.parseInt(id) % 2 !== 0;

  const rowConfig = {
    alternate,
    email,
    subject: email?.email_subject || "(No Subject)",
    type,
    edit: type === EMAIL_TYPES.DRAFT,
    onDelete,
    time: getTime(email, type),
    recepients: getRecepients(),
    onMoreClick: () => setOpenModal(true),
    onViewEditClick: handleViewEdit,
    onDuplicateClick: handleDuplicate,
    hasWriteAccess,
  };

  return (
    <>
      {isDesktop ? <DesktopRow {...rowConfig} /> : <MobileRow {...rowConfig} />}
      <MobilePopover
        paperClassName={classes.paperClassName}
        open={openModal}
        onClose={() => setOpenModal(false)}
      >
        {popoverOptions.map((option, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={index} className={classes.option} onClick={option.onClick}>
            {option.label}
          </div>
        ))}
      </MobilePopover>
    </>
  );
};

export default EmailListItem;
