import * as React from "react";
import constant from "../Constants/constant";
import module_style from "../../Style.module.css";

import { ArrowForwardIosRounded as ArrowIcon } from "@material-ui/icons";
import InfoIcon from "@material-ui/icons/InfoOutlined";

import ExlyModal from "common/Components/ExlyModal";
import withComponentLibraryTheme from "hocs/withComponentLibraryTheme";
import Message from "../../Message";
import { Button, Tooltip } from "@my-scoot/component-library-legacy";
import styles from "./Communication.module.css";
import { Edit } from "@material-ui/icons";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";

const ViewField = ({ communication, type, category, disableEdit }) => {
  const [showModal, setShowModal] = React.useState(false);
  const [view, setView] = React.useState(constant.view);
  const [save, setSave] = React.useState(constant.view_type_enums.initial);
  const [sendMessage, setSendMessage] = React.useState(0);
  const is_desktop = useDesktopMediaQuery();
  const show_test_cta = sessionStorage.getItem("is_creator_testable") == "true";

  const handleClose = () => {
    setShowModal(false);
    setSave(constant.view_type_enums.initial);
    setView(constant.view);
  };

  const tooltipTitle =
    "In the test message, all variables except for your name, phone and email will have sample values. Eg. {{listing_name}} will have ‘Yoga class’ as a sample value";

  const handleClick = () => {
    setShowModal(true);
    sessionStorage.setItem(
      "is_creator_testable",
      communication.is_creator_testable
    );
  };

  const changeView = () => {
    //State only changes when it is in a view mode. Save Increments
    if (view === constant.view) {
      setView(constant.edit_mode);
    }
    setSave((prev) => prev + 1);
  };

  return (
    <>
      <div
        className={`${module_style.purple_background} ${styles.backgroundContainer}`}
      >
        <div
          onClick={(e) => {
            e.preventDefault();
            handleClick();
          }}
          className={`float_right ${module_style.primaryTextOption}`}
        >
          {is_desktop && type === constant.user_type ? (
            <div className={styles.editIcon}>
              <Edit />
            </div>
          ) : null}
          View{type !== constant.creator_type && "/Edit"}{" "}
          <ArrowIcon className={module_style.arrowIcon} />
        </div>

        <ExlyModal
          padded={false}
          title={
            <div className={styles.modalHeader}>
              <p>View {type === constant.user_type ? "/ Edit" : ""} Message </p>
              <div className={styles.modalLabel}>
                Customize the message sent to customers.
              </div>
            </div>
          }
          header="View"
          open={showModal}
          onClose={handleClose}
          primaryBtnText="Edit"
          modal_props={{
            modalPaperClassName: styles.desktop_modal_paper,
          }}
          secondaryBtnText={
            show_test_cta == "true" ? "Send Me a Text Message" : null
          }
          customFooter={
            <div className={styles.modalFooterParent}>
              <div className={`${styles.modalFooter}`}>
                {show_test_cta && (
                  <div className={styles.tooltip}>
                    <Tooltip title={tooltipTitle}>
                      <InfoIcon className={`info`} />
                    </Tooltip>
                  </div>
                )}
                {show_test_cta && (
                  <div
                    className={
                      type === constant.creator_type
                        ? styles.margin_right_23
                        : styles.padding_adjust_footer
                    }
                  >
                    <Button
                      color="secondary"
                      variant="text"
                      className={`${styles.secondaryButton} ${
                        type === constant.creator_type
                          ? styles.secButtonWidth
                          : ""
                      }`}
                      onClick={() => setSendMessage((prev) => prev + 1)}
                    >
                      Send Me a Test Message{" "}
                    </Button>
                  </div>
                )}{" "}
                <div className={styles.editButton}>
                  {" "}
                  {type === constant.user_type && (
                    <Button
                      color="primary"
                      className={!show_test_cta && styles.mainButtonWidth}
                      variant="text"
                      onClick={() => changeView()}
                      disabled={disableEdit}
                    >
                      {view === constant.edit_mode ? "Save" : "Edit"}
                    </Button>
                  )}
                </div>
              </div>{" "}
            </div>
          }
        >
          <div className={styles.margin_bottom_20px}>
            <Message
              save={save}
              sendMessage={sendMessage}
              category={category}
              communicationTitle={communication.title}
              type={constant.email}
              user_type={type}
              view={view}
              setView={setView}
              setSave={setSave}
            />
          </div>
        </ExlyModal>
      </div>
    </>
  );
};

export default withComponentLibraryTheme(ViewField);
