import React from "react";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import DesktopDripItemLoader from "./DesktopDripItemLoader";
import MobileDripItemLoader from "./MobileDripItemLoader";

function EmailDripItemLoader(props) {
  const isDesktop = useDesktopMediaQuery();
  const Component = isDesktop ? DesktopDripItemLoader : MobileDripItemLoader;

  // adding 4 loading items, decent number not necessarily
  return [...new Array(4).keys()].map((item) => (
    <Component key={item} {...props} />
  ));
}

export default EmailDripItemLoader;
