import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import classnames from "classnames";

import style from "./PauseDripModal.module.css";
import ExlyModal from "common/Components/ExlyModal";
import { notification_color_keys, useNotifications } from "utils/hooks";
import { pauseEmailDrip } from "ui/pages/EmailDrip/EmailDrip.ApiCalls";
import { app_route_ids, app_route_keys } from "constants/urlPaths";

function PauseDripModal({ open, onClose, selectedDrip }) {
  const [pausing, setPausing] = useState(false);
  const { notify } = useNotifications();
  const history = useHistory();

  const handlePause = async () => {
    if (pausing) return;
    setPausing(true);
    const dripUuid = selectedDrip?.uuid;

    try {
      await pauseEmailDrip(dripUuid);
      notify(
        "Drip has been successfully paused.",
        notification_color_keys.success
      );
      onClose();
      history.push(`/${app_route_ids[app_route_keys.paused_drip]}`);
    } catch (err) {
      console.log(err, "Error pausing drip");
      notify(
        "Failed to pause, please try again.",
        notification_color_keys.error
      );
    } finally {
      setPausing(false);
    }
  };

  return (
    <ExlyModal
      open={open}
      onClose={onClose}
      title="Pausing drip?"
      desktopModalWidth="428px"
      padded={false}
      className={style.container}
      primaryBtnText="Pause"
      primaryBtnProps={{ color: "warning", fullWidth: true, loading: pausing }}
      onPrimaryBtnClick={handlePause}
      secondaryBtnText="Close"
      secondaryBtnProps={{
        color: "tertiary",
        fullWidth: true,
      }}
      footerBtnsPosition="space-between"
      showSecondaryBtn
      mobile_modal_props={{ footerClassName: style.footer }}
    >
      <span className="font_700">Note</span>: While your drip is paused, no
      drip-related comms will be sent to your selected audience.
      <span className={classnames(style.primaryText, "ml-1 mr-1")}>
        Although on resuming, the drip will not resume from where you have
        paused it.
      </span>
      It will still live through its timeline and continue as per the current
      time.
    </ExlyModal>
  );
}

export default PauseDripModal;
