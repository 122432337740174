import React, { useState } from "react";
import EmailCampaignLayout from "../layouts/EmailCampaignLayout";
import { dripTabValues, inactiveEmptyStateProps, tabValues } from "../data";
import { List, useListController } from "react-admin";
import DripTabLayout from "../layouts/DripTabLayout";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import { useToggleState } from "utils/hooks";
import useInactiveDripActions from "./useInactiveDripActions";
import ExlyTablePagination from "common/Components/ExlyTable/ExlyTablePagination";
import style from "./InactiveDrip.module.css";
import EmailDripItem from "../components/EmailDripItem/EmailDripItem";
import { fieldConfig, getDripHeader } from "../ActiveDrip/activeDripConfig";
import ActionDrawer from "common/Components/ActionDrawer/ActionDrawer";
import EndDripModal from "../modals/EndDripModal/EndDripModal";
import { emailStatsVariant } from "../components/EmailDripItem/EmailDripItem.constants";
import EmailDripItemLoader from "../components/EmailDripItem/EmailDripItemLoader";
import DripCampaignEmptyState from "../components/DripCampaignEmptyState/DripCampaignEmptyState";

const InActiveDrip = (props) => {
  const isDesktop = useDesktopMediaQuery();
  const { data, loading } = useListController(props);
  const inactiveDrips = Object.values(data);
  const [selectedDrip, setSelectedDrip] = useState(null);
  const [isMobileOptionsOpen, openMobileOptions, closeMobileOptions] =
    useToggleState(false);
  const [isDeleteModalOpen, openDeleteModal, closeDeleteModal] =
    useToggleState(false);

  const { writeDisabled, actions } = useInactiveDripActions({
    selectedDrip,
    setSelectedDrip,
    closeMobileOptions,
    openDeleteModal,
  });

  return (
    <EmailCampaignLayout selectedTab={tabValues.DRIP_CAMPAIGN} {...props}>
      <DripTabLayout selectedTab={dripTabValues.INACTIVE}>
        <List
          {...props}
          component={"div"}
          exporter={false}
          perPage={50}
          actions={null}
          empty={<DripCampaignEmptyState {...inactiveEmptyStateProps} />}
          pagination={
            <ExlyTablePagination
              classes={{ mobileWrapper: style.mobilePaginationWrapper }}
              borderedTop
            />
          }
        >
          <div className={style.listWrap}>
            {loading ? (
              <EmailDripItemLoader
                showStats
                statsVariant={emailStatsVariant.WITH_WITH_OVERLAY}
                showExtraRow={!isDesktop}
              />
            ) : (
              inactiveDrips.map((dripItem) => (
                <EmailDripItem
                  key={dripItem.uuid}
                  data={dripItem}
                  fieldConfig={fieldConfig}
                  showStats
                  statsVariant={emailStatsVariant.GREYED_OUT}
                  actions={actions}
                  onMobileActionsClick={(record) => {
                    setSelectedDrip(record);
                    openMobileOptions();
                  }}
                  writeDisabled={writeDisabled}
                  handleEdit={() => {}}
                  headerFormatter={getDripHeader}
                />
              ))
            )}
          </div>
        </List>
        <ActionDrawer
          open={isMobileOptionsOpen}
          onClose={() => {
            setSelectedDrip(null);
            closeMobileOptions();
          }}
          actions={actions}
        />
        <EndDripModal
          isPermananetDeletion
          open={isDeleteModalOpen}
          selectedDrip={selectedDrip}
          onClose={() => {
            setSelectedDrip(null);
            closeDeleteModal();
          }}
        />
      </DripTabLayout>
    </EmailCampaignLayout>
  );
};

export default InActiveDrip;
