import * as React from "react";
import module_style from "../../Style.module.css";
import ViewField from "./ViewField";
import { Card } from "@my-scoot/component-library-legacy";
import { Divider } from "@material-ui/core";
import EnableField from "./EnableField";

const MobileAccordianCard = ({
  communication,
  type,
  category,
  disableEdit,
}) => {
  return (
    <div className={module_style.padding_16}>
      <Card
        variant="outlined"
        shadow="none"
        className={module_style.border_radius_5}
      >
        <div className={module_style.padding_16}>
          <div className={`row no-gutters`}>
            <div className={`col-9`}>
              <div className={`${module_style.accordian_description_title}`}>
                {communication.cdb_type}
              </div>
            </div>
            <div className={`col-3`}>
              <div className={`float_right ${module_style.enableField}`}>
                <EnableField
                  communication={communication}
                  type={type}
                  category={category}
                  disableEdit={disableEdit}
                />
              </div>
            </div>
          </div>

          <Divider />
          <div
            className={`${module_style.accordian_description_sub_title} ${module_style.top_margin_10}`}
          >
            Time: {communication.trigger}
          </div>
        </div>
        <div>
          <ViewField
            communication={communication}
            type={type}
            category={category}
            disableEdit={disableEdit}
          />
        </div>
      </Card>
    </div>
  );
};

export default MobileAccordianCard;
