import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { DRIP_TAB_CONFIG } from "../data";
import TabLayout from "common/Layouts/TabLayout";

const useStyles = makeStyles(() => ({
  tabsScroller: {
    borderBottom: "none !important",
  },
}));

const DripTabLayout = ({ children, selectedTab }) => {
  const classes = useStyles();

  return (
    <TabLayout
      selectedTab={selectedTab}
      tabConfig={DRIP_TAB_CONFIG}
      tabProps={{
        tabVariant: "filled_primary",
        classes: { scroller: classes.tabsScroller },
      }}
      showTabsBottomBorder={false}
      classes={{ scroller: classes.tabsScroller }}
    >
      {children}
    </TabLayout>
  );
};

export default DripTabLayout;
