import React from "react";

import DataItem from "../DataItem";

import { useStyles } from "./MobileRow.styles";

import EmailIcon from "assets/images/email.svg";

import { IconButton } from "@material-ui/core";

import { MoreHorizOutlined as MoreIcon } from "@material-ui/icons";

import classnames from "classnames";
import { getStatusChip } from "../getStatusChip";
import {
  CAMPAIGN_DESCRIPTIONS,
  CAMPAIGN_TYPES,
  EMAIL_TYPES,
} from "ui/pages/communications/CustomEmail/data";
import { Tooltip } from "@my-scoot/component-library-legacy";
import { campaignStepsData } from "../../utils";
import InfoIcon from "@material-ui/icons/InfoOutlined";

const MobileRow = ({
  alternate,
  email,
  recepients,
  type,
  subject,
  time,
  onViewEditClick,
  onMoreClick,
  hasWriteAccess,
}) => {
  const classes = useStyles();

  const { campaign_step, campaign_type, added_by_name } = email;

  const getTimePrefix = () => {
    if (type === EMAIL_TYPES.SCHEDULED)
      return <span className={classes.scheduledPrefix}>Scheduled For</span>;
    if (type === EMAIL_TYPES.DRAFT) return "Edited";
    return campaign_type === CAMPAIGN_TYPES.NEW
      ? "Campaign start time:"
      : "Sent";
  };

  return (
    <>
      <div
        className={classnames(
          classes.root,
          classes.mobileRoot,
          alternate && classes.dark,
          !hasWriteAccess && "disable_pointer"
        )}
      >
        <div className={classes.mobileHeader}>
          <div className={classes.headerLeftContainer}>
            <img src={EmailIcon} alt="email" />
            <span className={classes.mailTo} onClick={onViewEditClick}>
              To: {recepients}
            </span>
          </div>

          <div className={classes.headerRightContainer}>
            {getStatusChip({ type, classes })}

            <IconButton className={classes.moreIcon} onClick={onMoreClick}>
              <MoreIcon />
            </IconButton>
          </div>
        </div>

        <div className={classes.subject}>Subject: {subject}</div>

        {time && (
          <div className={classes.dateTime}>
            {getTimePrefix()} {time}
          </div>
        )}

        {type === EMAIL_TYPES.PAST && (
          <div
            className={classnames(classes.dataContainer, classes.mobileFooter)}
          >
            <DataItem
              dataClassName={classes.sentTo}
              title="Sent to"
              data={email?.sent_to_count ?? 0}
            />
            <DataItem
              dataClassName={classes.openedBy}
              title="Opened by"
              data={email?.opened_count ?? 0}
            />
            <DataItem
              dataClassName={classes.openedRate}
              title="Opened rate(%)"
              data={(email?.open_rate_count ?? 0).toFixed(2)}
            />
          </div>
        )}
      </div>

      {campaign_type === CAMPAIGN_TYPES.NEW && (
        <div className={classes.stepContainer}>
          <Tooltip
            arrow
            color="primary"
            placement="top"
            title={added_by_name ? `Added by ${added_by_name}` : ""}
          >
            <span
              className={classnames(
                classes.campaignStep,
                classes[campaignStepsData[campaign_step].className]
              )}
            >
              {campaignStepsData[campaign_step].label}
            </span>
          </Tooltip>
          <Tooltip
            arrow
            color="primary"
            placement="top"
            title={CAMPAIGN_DESCRIPTIONS[campaign_step]}
          >
            <InfoIcon />
          </Tooltip>
        </div>
      )}
    </>
  );
};

export default MobileRow;
