import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    padding: "16px 0px 16px 12px",
    border: `1.5px solid ${theme?.palette?.secondary?.shade_100}`,
    borderRadius: "8px",
    fontFamily: theme?.typography?.fontFamily,
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
  },
  content: {
    width: "100%",
  },
  leftContainer: {},
  rightContainer: {
    marginTop: "-12px",
  },
  mailTo: {
    color: theme?.palette?.secondary?.main,
    fontSize: "16px",
    fontWeight: 500,
    textDecoration: "underline",
    marginBottom: ({ isDesktop }) => isDesktop && "12px",
    cursor: "pointer",
    "&:hover": {
      color: theme?.palette?.primary?.main,
    },
  },
  subject: {
    color: theme?.palette?.secondary?.main,
    fontSize: "16px",
    marginBottom: "10px",
  },
  dateTime: {
    fontSize: "14px",
    color: theme?.palette?.secondary?.main,
    marginBottom: ({ isDesktop }) => !isDesktop && "12px",
  },
  emailIcon: {
    height: "24px",
    width: "24px",
    marginRight: "12px",
  },
  copyIcon: {
    height: "24px",
  },
  iconButton: {
    padding: "12px",
  },
  deleteIcon: {
    "& :hover": {
      color: theme?.palette?.basic?.danger_red,
    },
  },
  divider: {
    border: "none",
    height: "2px",
    background: theme?.palette?.secondary?.shade_100,
    margin: "10px 0px",
  },
  itemData: {
    fontSize: "16px",
    fontWeight: 700,
    marginBottom: "8px",
  },
  itemTitle: {
    fontSize: "16px",
    color: theme?.palette?.secondary?.main,
  },
  horizontalDivider: {
    width: "1.5px",
    background: "#C4C4C4",
  },
  dataContainer: {
    display: "flex",
    gap: ({ isDesktop }) => (isDesktop ? "16px" : "40px"),
  },
  sentTo: {
    color: theme?.palette?.primary?.main,
  },
  openedBy: {
    color: "#00B77A",
  },
  openedRate: {
    color: theme?.palette?.persian_blue?.main,
  },
  dark: {
    background: theme?.palette?.secondary?.shade_50,
  },
  mobileRoot: {
    display: "block",
  },
  mobileHeader: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: `1.5px solid ${theme?.palette?.secondary?.shade_100}`,
    paddingBottom: "10px",
    marginBottom: "10.5px",
  },
  headerLeftContainer: {
    display: "flex",
    alignItems: "center",
    gap: "12px",
  },
  moreIcon: {
    padding: 0,
    marginRight: "16px",
    color: theme?.palette?.primary?.main,
  },
  mobileFooter: {
    background: theme?.palette?.primary?.shade_50,
    borderRadius: "0px 0px 5px 5px",
    margin: "0px -1.5px -17.5px -13.5px",
    padding: "12px",
  },
  paperClassName: {
    padding: "16px",
  },
  option: {
    fontSize: "16px",
    marginBottom: "14px",
  },
}));
