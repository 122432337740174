import React from "react";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import DesktopEmailDripItem from "./DesktopEmailDripItem";
import MobileEmailDripItem from "./MobileEmailDripItem";

function EmailDripItem(props) {
  const isDesktop = useDesktopMediaQuery();
  const Component = isDesktop ? DesktopEmailDripItem : MobileEmailDripItem;
  return <Component {...props} />;
}

export default EmailDripItem;
