import { handleDateFormat } from "utils/Utils";
import { CAMPAIGN_STEPS, EMAIL_TYPES } from "../../data";

export const campaignStepsData = {
  [CAMPAIGN_STEPS.CAMPAIGN_ADDED]: {
    className: "campaignAdded",
    label: "Campaign Added",
  },
  [CAMPAIGN_STEPS.PROCESSING]: {
    className: "campaignProcessing",
    label: "Campaign Processing",
  },
  [CAMPAIGN_STEPS.CAMPAIGN_FAILED]: {
    className: "campaignFailed",
    label: "Campaign Failed",
  },
  [CAMPAIGN_STEPS.CAMPAIGN_COMPLETED]: {
    className: "campaignCompleted",
    label: "Campaign Completed",
  },
  [CAMPAIGN_STEPS.INSUFFICIENT_CREDITS]: {
    className: "campaignInsufficientCredits",
    label: "Insufficient credits",
  },
};

export const trimRecipientLabels = (
  labels,
  itemsLength = 0,
  countSuffix = ""
) => {
  return labels?.join(", ").concat(
    // showing ellipsis if more than 2 elements along with remaning items count
    // optional: count suffix eg, ... +10 more
    itemsLength > 2 ? ` ... +${itemsLength - 2} ${countSuffix ?? ""}` : ""
  );
};

export const getTime = (email, type) => {
  let source = "";

  if (type === EMAIL_TYPES.PAST) source = "trigger_time";
  if (type === EMAIL_TYPES.DRAFT) source = "updated_at";
  if (type === EMAIL_TYPES.SCHEDULED) source = "scheduled_times";

  const time = email?.[source];

  return time ? `${handleDateFormat(time)}` : "";
};
