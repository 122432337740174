import React, { useState } from "react";
import EmailCampaignLayout from "../layouts/EmailCampaignLayout";
import { dripTabValues, pausedEmptyStateProps, tabValues } from "../data";
import { List, useListController } from "react-admin";
import DripTabLayout from "../layouts/DripTabLayout";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import {
  notification_color_keys,
  useNotifications,
  useToggleState,
} from "utils/hooks";
import usePausedDripActions from "./usePausedDripActions";
import ExlyTablePagination from "common/Components/ExlyTable/ExlyTablePagination";
import style from "./PausedDrip.module.css";
import EmailDripItem from "../components/EmailDripItem/EmailDripItem";
import ActionDrawer from "common/Components/ActionDrawer/ActionDrawer";
import { fieldConfig, getDripHeader } from "../ActiveDrip/activeDripConfig";
import EndDripModal from "../modals/EndDripModal/EndDripModal";
import ExlyModal from "common/Components/ExlyModal";
import { app_route_ids, app_route_keys } from "constants/urlPaths";
import { emailStatsVariant } from "../components/EmailDripItem/EmailDripItem.constants";
import EmailDripItemLoader from "../components/EmailDripItem/EmailDripItemLoader";
import { useEmailDrip } from "ui/pages/EmailDrip/useEmailDrip";
import { CheckCreditExpiry } from "common/Components/UBP/CheckCreditExpiry";
import { FEATURE_KEYS } from "common/Components/UBP/ubp.constants";
import withComponentLibraryTheme from "hocs/withComponentLibraryTheme";
import DripCampaignEmptyState from "../components/DripCampaignEmptyState/DripCampaignEmptyState";

const PausedDrip = (props) => {
  const isDesktop = useDesktopMediaQuery();
  const { data, loading } = useListController(props);
  const { notify } = useNotifications();
  const { resumeEmailDrip } = useEmailDrip();

  const pausedDrips = Object.values(data);
  const { history } = props;
  const [selectedDrip, setSelectedDrip] = useState(null);
  const [isMobileOptionsOpen, openMobileOptions, closeMobileOptions] =
    useToggleState(false);
  const [isEndModalOpen, openEndModal, closeEndModal] = useToggleState(false);
  const [isResumeModalOpen, openResumeModal, closeResumeModal] =
    useToggleState(false);
  const [isResuming, setIsResuming] = useState(false);
  const { writeDisabled, handleEdit, actions } = usePausedDripActions({
    selectedDrip,
    setSelectedDrip,
    closeMobileOptions,
    openEndModal,
    openResumeModal,
  });

  async function onResume() {
    if (isResuming) return;
    setIsResuming(true);

    try {
      await resumeEmailDrip(selectedDrip?.uuid);
      notify(
        "Drip has successfully been resumed!",
        notification_color_keys.success
      );
      closeResumeModal();
      history.push(`/${app_route_ids[app_route_keys.active_drip]}`);
    } catch (error) {
      notify(
        error?.message || "Failed to resume the drip",
        notification_color_keys.error
      );
    } finally {
      setIsResuming(false);
    }
  }

  return (
    <>
      {isDesktop && (
        <CheckCreditExpiry feature_key={FEATURE_KEYS.email_marketing.key} />
      )}
      <EmailCampaignLayout selectedTab={tabValues.DRIP_CAMPAIGN} {...props}>
        <DripTabLayout selectedTab={dripTabValues.PAUSED}>
          <List
            {...props}
            component={"div"}
            exporter={false}
            perPage={50}
            empty={<DripCampaignEmptyState {...pausedEmptyStateProps} />}
            pagination={
              <ExlyTablePagination
                classes={{ mobileWrapper: style.mobilePaginationWrapper }}
                borderedTop
              />
            }
            // pagination={<ListPagination />}
            actions={null}
          >
            <div className={style.listWrap}>
              {loading ? (
                <EmailDripItemLoader
                  showStats
                  statsVariant={emailStatsVariant.WITH_WITH_OVERLAY}
                  showExtraRow={!isDesktop}
                />
              ) : (
                pausedDrips.map((dripItem) => (
                  <EmailDripItem
                    key={dripItem.uuid}
                    data={dripItem}
                    fieldConfig={fieldConfig}
                    showStats
                    statsVariant={emailStatsVariant.WITH_WITH_OVERLAY}
                    actions={actions}
                    onMobileActionsClick={(record) => {
                      setSelectedDrip(record);
                      openMobileOptions();
                    }}
                    writeDisabled={writeDisabled}
                    handleEdit={handleEdit}
                    headerFormatter={getDripHeader}
                  />
                ))
              )}
            </div>
          </List>
          <ActionDrawer
            open={isMobileOptionsOpen}
            onClose={() => {
              setSelectedDrip(null);
              closeMobileOptions();
            }}
            actions={actions}
          />
          <EndDripModal
            open={isEndModalOpen}
            selectedDrip={selectedDrip}
            onClose={() => {
              setSelectedDrip(null);
              closeEndModal();
            }}
          />
          {isResumeModalOpen && (
            <ExlyModal
              open={isResumeModalOpen}
              onClose={closeResumeModal}
              title="Resuming drip"
              desktopModalWidth="428px"
              primaryBtnText="Resume"
              secondaryBtnText="Close"
              onPrimaryBtnClick={onResume}
              className={style.noteContent}
              secondaryBtnProps={{ color: "tertiary", fullWidth: true }}
              showSecondaryBtn
              primaryBtnProps={{ fullWidth: true, loading: isResuming }}
              footerBtnsPosition="space-between"
              mobile_modal_props={{ footerClassName: style.footer }}
            >
              <span className="font_600 mr-1">
                This action will resume your drip campaign.
              </span>
              As previously stated, the drip will resume as per where it would
              have been if the drip was not initially paused.
            </ExlyModal>
          )}
        </DripTabLayout>
      </EmailCampaignLayout>
    </>
  );
};

export default withComponentLibraryTheme(PausedDrip);
