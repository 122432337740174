import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    padding: "16px 0px 16px 12px",
    border: `1.5px solid ${theme?.palette?.secondary?.shade_100}`,
    borderRadius: "8px",
    fontFamily: theme?.typography?.fontFamily,
  },
  mailTo: {
    color: theme?.palette?.secondary?.main,
    fontSize: "16px",
    fontWeight: 500,
    cursor: "pointer",
    maxWidth: "48vw",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    "&:hover": {
      color: theme?.palette?.primary?.main,
      textDecoration: "underline",
    },
  },
  subject: {
    color: theme?.palette?.secondary?.main,
    fontSize: "16px",
    marginBottom: "10px",
  },
  dateTime: {
    fontSize: "14px",
    color: theme?.palette?.secondary?.main,
  },
  dataContainer: {
    display: "flex",
    gap: "40px",
  },
  sentTo: {
    color: theme?.palette?.primary?.main,
  },
  openedBy: {
    color: "#00B77A",
  },
  openedRate: {
    color: theme?.palette?.persian_blue?.main,
  },
  dark: {
    background: theme?.palette?.secondary?.shade_50,
  },
  mobileRoot: {
    display: "block",
  },
  mobileHeader: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: `1.5px solid ${theme?.palette?.secondary?.shade_100}`,
    paddingBottom: "10px",
    marginBottom: "10.5px",
  },
  headerLeftContainer: {
    display: "flex",
    alignItems: "center",
    gap: "12px",
  },
  headerRightContainer: {
    display: "flex",
    gap: "14px",
    alignItems: "center",
  },
  moreIcon: {
    padding: 0,
    marginRight: "16px",
    color: theme?.palette?.primary?.main,
  },
  mobileFooter: {
    background: theme?.palette?.primary?.shade_50,
    borderRadius: "0px 0px 5px 5px",
    margin: "0px -1.5px -17.5px -13.5px",
    padding: "12px",
    marginTop: "12px",
  },
  paperClassName: {
    padding: "16px",
  },
  option: {
    fontSize: "16px",
    marginBottom: "14px",
  },
  chip: {
    height: "23px",
    display: "flex",
    alignItems: "center",
    fontSize: "12px",
    padding: "0px 6px",
    borderRadius: "11.5px",
    textDecoration: "none",
  },
  draftChip: {
    background: theme?.palette?.coral_red?.shade_100,
    color: theme?.palette?.coral_red?.shade_600,
  },
  scheduledChip: {
    background: theme?.palette?.butterscotch_yellow?.shade_100,
    color: "#FFAA00",
  },
  stepContainer: {
    border: `1.5px solid ${theme?.palette?.secondary?.shade_100}`,
    borderTop: "none",
    padding: "8px",
    marginTop: "-2px",
    borderRadius: "0 0 8px 8px",
    display: "flex",
    alignItems: "center",
    gap: "6px",
  },
  campaignStep: {
    borderRadius: "14px",
    fontSize: "12px",
    padding: "6px",
    color: theme?.palette?.basic?.white,
  },
  campaignAdded: {
    background: theme?.palette?.primary?.shade_500,
  },
  campaignProcessing: {
    background: theme?.palette?.butterscotch_yellow?.shade_500,
  },
  campaignFailed: {
    background: theme?.palette?.coral_red?.main,
  },
  campaignCompleted: {
    background: theme?.palette?.fine_pine?.shade_500,
  },
  campaignInsufficientCredits: {
    background: "#FC8542",
  },
}));
