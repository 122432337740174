import { makeStyles } from "@material-ui/core";
import React from "react";

import classnames from "classnames";

const useStyles = makeStyles((theme) => ({
  itemData: {
    fontSize: "16px",
    fontWeight: 700,
    marginBottom: "8px",
  },
  itemTitle: {
    fontSize: "16px",
    color: theme?.palette?.secondary?.main,
  },
}));

const DataItem = ({ data, title, titleClassName, dataClassName }) => {
  const classes = useStyles();

  return (
    <div>
      <div className={classnames(classes.itemData, dataClassName)}>{data}</div>
      <div className={classnames(classes.itemTitle, titleClassName)}>
        {title}
      </div>
    </div>
  );
};

export default DataItem;
