import React from "react";
import style from "../../../../Style.module.css";
import { Tabs } from "@my-scoot/component-library-legacy";
import CreatorList from "./Components/CreatorList";
import UserList from "./Components/UserList";
import withComponentLibraryTheme from "hocs/withComponentLibraryTheme";
import constant, {
  AUTOMATED_MESSAGES_LEARN_MORE_HREFS,
} from "./Constants/constant";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import useHideNavAndAppBar from "hooks/useHideNavAndAppBar";
import { ManageSenderDetails } from "features/AutomatedCommunication/modules/ManageSenderDetails";
import { Header } from "common/Components/Header";
import LearnMoreCta from "features/Common/modules/LearnMore/modules/LearnMoreCta/LearnMoreCta";
import moduleStyles from "./index.module.css";
import useAppLayoutControl from "hooks/useAppLayoutControl";

const AutomatedMessages = () => {
  const is_desktop = useDesktopMediaQuery();

  const [currentTab, setCurrentTab] = React.useState(constant.user_type);

  const handleChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  useHideNavAndAppBar(constant.page_title);
  useAppLayoutControl({
    values: {
      learnMoreHref: AUTOMATED_MESSAGES_LEARN_MORE_HREFS.AUTOMATED_MESSAGES,
    },
  });

  return (
    <div className="dm_font component-wrapper">
      <div className={`mb-4`}>
        {is_desktop ? (
          <Header
            title={constant.page_title}
            right={
              <LearnMoreCta
                href={AUTOMATED_MESSAGES_LEARN_MORE_HREFS.AUTOMATED_MESSAGES}
              />
            }
            classNames={{ container: moduleStyles.headerContainer }}
            hideBackButton
            showLearnMoreCta
          />
        ) : null}
        <p className={`${style.sub_title_header}`}>
          Send customizable emails to your customers about their invoices,
          purchases, subscription reminders, and more. You can even switch these
          communications on or off based on your preference.
        </p>
      </div>
      <div className="position-relative">
        <Tabs
          tabs={constant.TABS}
          value={currentTab}
          onChange={handleChange}
          tabVariant="outlined"
        />
        <ManageSenderDetails />
      </div>

      {currentTab === constant.user_type ? <UserList /> : <CreatorList />}
    </div>
  );
};

export default withComponentLibraryTheme(AutomatedMessages);
