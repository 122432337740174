import constants from "constants/constants";
import { isInteger, is_empty } from "utils/validations";
import { const_data } from "../../data";
import { RECIPIENT_KEYS } from "./RecipientsSelector.constants";
import {
  RECIPIENT_SUB_SECTION_LABELS,
  TOTAL_EMAIL_RECIPIENTS_SUBSECTIONS,
} from "features/EmailCampaigns/constants/EmailCampaigns.constants";

export const getRecipientName = ({
  displayName,
  listingType,
  userCount,
  subSectionType,
  isAllCustomers,
}) => {
  return `${displayName.substring(0, const_data.group_max_length)}${
    displayName.length > const_data.group_max_length ? "..." : ""
  }${
    isInteger(listingType)
      ? ` - ${constants.schedule_type[listingType].name}`
      : ""
  }${
    subSectionType ? ` - ${RECIPIENT_SUB_SECTION_LABELS[subSectionType]}` : ""
  }${isAllCustomers ? " - All customers" : ""}${
    userCount !== undefined ? ` (${userCount})` : ""
  }`;
};

export const formatRecipients = (recipeints) => {
  if (is_empty(recipeints)) return [];

  return recipeints.reduce((resultantRecipients, recipeint) => {
    const { [RECIPIENT_KEYS.sub_sections]: subSections, ...recipientData } =
      recipeint;

    // add sub sections as seperate recipient containing all common props from parent section
    if (is_empty(subSections)) {
      // add current section
      resultantRecipients.push(recipeint);
    } else {
      if (subSections.length === TOTAL_EMAIL_RECIPIENTS_SUBSECTIONS) {
        // add current section
        resultantRecipients.push(recipeint);
      }

      subSections.forEach((subSection) => {
        const {
          [RECIPIENT_KEYS.sub_section_type]: subSectionType,
          [RECIPIENT_KEYS.user_count]: userCount,
        } = subSection;
        const newRecipientDataCreatedWithSubsection = {
          ...recipientData,
          [RECIPIENT_KEYS.sub_section_type]: subSectionType,
          [RECIPIENT_KEYS.user_count]: userCount,
        };

        resultantRecipients.push(newRecipientDataCreatedWithSubsection);
      });
    }

    return resultantRecipients;
  }, []);
};
