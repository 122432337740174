import ExlyEmptyState from "features/Common/modules/ExlyEmptyState/ExlyEmptyState";
import React, { FC } from "react";
import { useEmailCampaignLayoutContext } from "../../CustomEmail.utils";
import { EMAIL_CAMPAIGNS_LEARN_MORE_HREFS } from "../../data";
import { EXLY_EMPTY_STATE_SECONDARY_CTA_VARIANTS } from "features/Common/modules/ExlyEmptyState/constants/ExlyEmptyState.constants";
import useFeatureOnboardingChecker from "common/Components/FeatureOnboarding/useFeatureOnboardingChecker";
import { onboardingFeatureKeys } from "common/Components/FeatureOnboarding/FeatureOnboarding.constants";

const CustomEmailEmptyState: FC<{
  title: string;
  description: string;
  showPrimaryCta: boolean;
  imgSrc?: string;
  videoSrc?: string;
}> = ({ title, description, showPrimaryCta, videoSrc, imgSrc }) => {
  const { handleCompose } = useEmailCampaignLayoutContext();
  const {
    isOnboardedPrevVal: isFeatureVisited,
    flagLoading: isFeatureFlagLoading,
    handleFeatureOnboardChange,
  } = useFeatureOnboardingChecker({
    featureKey: onboardingFeatureKeys.KNOWLEDGE_BASE.EMAIL_CAMPAIGNS,
    // @ts-ignore
    successCallback: (marked: boolean) =>
      !marked && handleFeatureOnboardChange(true),
  });

  return (
    <ExlyEmptyState
      isLoading={isFeatureFlagLoading}
      title={title}
      description={description}
      imgSrc={imgSrc}
      videoSrc={videoSrc}
      playerProps={{ playing: !isFeatureFlagLoading && !isFeatureVisited }} // autoplay the knowledge base video on the first visit to this feature
      primaryCtaProps={
        showPrimaryCta
          ? {
              title: "Compose Email",
              onClick: handleCompose,
            }
          : undefined
      }
      secondaryCtaProps={{
        variant:
          EXLY_EMPTY_STATE_SECONDARY_CTA_VARIANTS.learn_more as keyof typeof EXLY_EMPTY_STATE_SECONDARY_CTA_VARIANTS,
        learnMoreHref: EMAIL_CAMPAIGNS_LEARN_MORE_HREFS.EMAIL_CAMPAIGNS,
      }}
      className="mt-4"
    />
  );
};

export default CustomEmailEmptyState;
