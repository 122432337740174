export const apiKeys = {
  campaignName: "title",
  trigger: "trigger_type",
  totalSubscribers: "total_subscribers",
  emails: "no_of_emails",
  totalEmailsSent: "total_sent_to_count",
  totalOpenCount: "total_opened_count",
  unsubscribedCount: "unsubscribe_count",
  offeringName: "listing_title",
  totalClicksNumber: "total_clicks_number",
  totalHyperlinks: "total_hyperlinks",
  subscribeTriggerCount: "subscribe_trigger_count",
  unsubscribeTriggerCount: "unsubscribe_trigger_count",
  clickRate: "click_rate",
};

export const fieldConfig = [
  {
    label: "Triggers",
    source: apiKeys.trigger,
    format: (data) =>
      `${data[apiKeys.subscribeTriggerCount]} subscribe, ${
        data[apiKeys.unsubscribeTriggerCount]
      } unsubscribe`,
  },
  {
    label: "Emails",
    source: apiKeys.emails,
  },
  {
    label: "Total subscribers",
    source: apiKeys.totalSubscribers,
    format: (data) => data[apiKeys.totalSubscribers] || 0,
  },
];

export const getDripHeader = (data) => data[apiKeys.campaignName];
