import React from "react";
import {
  List,
  Pagination,
  SimpleList,
  useListController,
  useRefresh,
} from "react-admin";

import constants from "../../../../../constants/constants";
import { is_empty } from "../../../../../utils/validations";
import EmailListItem from "../components/EmailListItem/EmailListItem";

import { DraftsLoadingShimmer } from "../components";

import { useStyles } from "./scheduledEmails.styles";
import ExlyDeleteModal from "common/Components/ExlyDeleteModal/ExlyDeleteModal";
import EmailCampaignLayout from "../layouts/EmailCampaignLayout";
import { tabValues } from "../data";
import withComponentLibraryTheme from "hocs/withComponentLibraryTheme";
import { deleteEmail } from "../Email.helpers";
import { RESOURCE_KEYS, orgPermissions } from "utils/OrgPermissions";
import { useNotifications } from "utils/hooks";
import CustomEmailEmptyState from "../components/CustomEmailEmptyState/CustomEmailEmptyState";

const ScheduledEmails = (props) => {
  const classes = useStyles();
  const { data, loading } = useListController(props);
  const hasWriteAccess = orgPermissions.hasFeatureWriteAccess(
    RESOURCE_KEYS.SECTIONS.CUSTOM_MAILS
  );
  const { notify } = useNotifications();
  const refresh = useRefresh();

  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [selectedEmail, setSelectedEmail] = React.useState(null);

  const ListPagination = (props) => (
    <Pagination rowsPerPageOptions={[]} {...props} />
  );

  const handleDelete = (email) => {
    setSelectedEmail(email);
    setShowDeleteModal(true);
  };

  return (
    <EmailCampaignLayout selectedTab={tabValues.SCHEDULED_EMAIL} {...props}>
      {loading && <DraftsLoadingShimmer />}

      {!loading && is_empty(data) && (
        <CustomEmailEmptyState
          title="You have no emails scheduled. Schedule one now."
          description="Send emails to your leads or customers. Share your upcoming events, publish a newsletter, provide an important update or just send a greeting"
          imgSrc="/assets/vectors/sales_and_marketing/broadcast/scheduled_illustration.svg"
        />
      )}

      {!loading && !is_empty(data) && (
        <List
          style={
            window.screen.width > constants.mobile_width
              ? {}
              : { width: "100%", padding: 0, overflow: "hidden" }
          }
          id={"past_emails_list"}
          component={"div"}
          exporter={false}
          {...props}
          perPage={50}
          pagination={<ListPagination />}
          actions={null}
        >
          <div>
            <SimpleList
              className={classes.simpleListRoot}
              rowStyle={() => ({ padding: 0, margin: "6px 0" })}
              primaryText={(email, id) => (
                <EmailListItem
                  id={id}
                  type="scheduled"
                  email={email}
                  key={email.uuid}
                  onDelete={() => handleDelete(email)}
                  hasWriteAccess={hasWriteAccess}
                />
              )}
              linkType={false}
            />
          </div>
        </List>
      )}

      <ExlyDeleteModal
        open={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        title="Are you sure?"
        description={`Deleting this email from scheduled emails. It will still be visible on your emailing plaftform.`}
        onDelete={() =>
          deleteEmail(selectedEmail, "scheduled", notify, () => {
            refresh();
            setShowDeleteModal(false);
          })
        }
      />
    </EmailCampaignLayout>
  );
};

export default withComponentLibraryTheme(ScheduledEmails);
