import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(
  (theme) => ({
    stickyButton: {
      width: "100%",
      position: "fixed",
      bottom: 0,
      background: theme?.palette?.basic?.white,
      borderTop: `2px solid ${theme?.palette?.secondary?.shade_100}`,
      margin: "0px -16px",
      padding: "12px 16px",
    },
    simpleListRoot: {
      paddingTop: 0,
      paddingLeft: "0 !important",
    },
  }),
  { name: "CreatorTool" }
);
