import dataProvider from "data/dataProvider";
import * as React from "react";
import { Switch } from "@my-scoot/component-library-legacy";
import { useNotifications } from "utils/hooks";
import { orgPermissions, RESOURCE_KEYS } from "utils/OrgPermissions";
import { is_empty } from "utils/validations";
import constant from "../Constants/constant";

const EnableField = ({ communication, disableEdit }) => {
  const { notify } = useNotifications();
  const [checkedState, setCheckedState] = React.useState(communication.enabled);

  const handleChangeSwitch = async () => {
    setCheckedState(!checkedState);
    try {
      const status = await dataProvider.custom_request(
        `notifications/exly/${communication.title}/update`,
        "POST",
        { enabled: !checkedState }
      );
      notify(status.message, "success");
    } catch (err) {
      console.log(err);
      notify(
        !is_empty(err?.body?.message)
          ? err.body.message
          : constant.error_message,
        "error"
      );
    }
  };
  return (
    <>
      {orgPermissions.canToggle(
        RESOURCE_KEYS.SECTIONS.COMMUNICATIONS,
        RESOURCE_KEYS.SUBSECTIONS.COMMUNICATIONS.AUTOMATED_MESSAGES,
        RESOURCE_KEYS.TABS.COMMUNICATIONS.AUTOMATED_MESSAGES.SENT_TO_USER,
        "status"
      ) ? (
        <Switch
          checked={checkedState}
          size="large"
          onChange={handleChangeSwitch}
          color="success"
          disabled={disableEdit}
        />
      ) : (
        <div>{communication.enabled ? "Enabled" : "Disabled"}</div>
      )}
    </>
  );
};

export default EnableField;
