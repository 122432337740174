import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    padding: "16px 0px 16px 12px",
    border: `1.5px solid ${theme?.palette?.secondary?.shade_100}`,
    borderRadius: "8px",
    fontFamily: theme?.typography?.fontFamily,
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
  },
  content: {
    width: "100%",
  },
  leftContainer: {
    flex: 1,
  },
  rightContainer: {
    marginTop: "-12px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  mailTo: {
    display: "flex",
    gap: "20px",
    marginBottom: ({ type }) => type !== "past" && "10px",
    borderBottom: ({ type }) =>
      type !== "past" && `2px solid ${theme?.palette?.secondary?.shade_100}`,
  },
  recepients: {
    color: theme?.palette?.secondary?.main,
    fontSize: "16px",
    fontWeight: 500,
    marginBottom: "12px",
    cursor: "pointer",
    "&:hover": {
      color: theme?.palette?.primary?.main,
      textDecoration: "underline",
    },
  },
  subject: {
    color: theme?.palette?.secondary?.main,
    fontSize: "16px",
    marginBottom: "10px",
  },
  dateTime: {
    fontSize: "14px",
    color: theme?.palette?.secondary?.main,
  },
  emailIcon: {
    height: "24px",
    width: "24px",
    paddingBottom: "12px",
    paddingRight: "12px",
    boxSizing: "content-box",
    borderBottom: ({ type }) =>
      type !== "past" && `2px solid ${theme?.palette?.secondary?.shade_100}`,
  },
  copyIcon: {
    height: "18px",
  },
  iconButton: {
    padding: "12px",
  },
  deleteIcon: {
    "& :hover": {
      color: theme?.palette?.basic?.danger_red,
    },
  },
  divider: {
    border: "none",
    height: "2px",
    background: theme?.palette?.secondary?.shade_100,
    margin: "10px 0px",
  },
  itemData: {
    fontSize: "16px",
    fontWeight: 700,
    marginBottom: "8px",
  },
  itemTitle: {
    fontSize: "16px",
    color: theme?.palette?.secondary?.main,
  },
  horizontalDivider: {
    width: "1.5px",
    background: "#C4C4C4",
  },
  dataContainer: {
    display: "flex",
    gap: "16px",
  },
  sentTo: {
    color: theme?.palette?.primary?.main,
  },
  openedBy: {
    color: "#00B77A",
  },
  openedRate: {
    color: "#0052CC",
  },
  dark: {
    background: "#F3F2F1",
  },
  scheduledPrefix: {
    color: "#FFAA00",
  },
  chip: {
    height: "23px",
    display: "flex",
    alignItems: "center",
    fontSize: "12px",
    padding: "0px 6px",
    borderRadius: "11.5px",
    textDecoration: "none",
  },
  draftChip: {
    background: theme?.palette?.coral_red?.shade_100,
    color: theme?.palette?.coral_red?.shade_600,
  },
  scheduledChip: {
    background: theme?.palette?.butterscotch_yellow?.shade_100,
    color: "#FFAA00",
  },
  iconContainer: {
    borderBottom: ({ type }) =>
      type !== "past" && `2px solid ${theme?.palette?.secondary?.shade_100}`,
  },
  bottomDataContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
  },
  campaignStep: {
    borderRadius: "14px",
    fontSize: "12px",
    padding: "6px",
    textAlign: "center",
    color: theme?.palette?.basic?.white,
  },
  campaignAdded: {
    background: theme?.palette?.primary?.shade_500,
  },
  campaignProcessing: {
    background: theme?.palette?.butterscotch_yellow?.shade_500,
  },
  campaignFailed: {
    background: theme?.palette?.basic?.danger_red,
  },
  campaignCompleted: {
    background: theme?.palette?.fine_pine?.shade_500,
  },
  campaignInsufficientCredits: {
    background: "#FC8542",
  },
  timeContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  campaignDataContainer: {
    display: "flex",
    gap: "6px",
    marginRight: "16px",
    alignItems: "center",
  },
}));
