import React from "react";
import { is_empty } from "../../../../../utils/validations";
import constant from "../Constants/constant";
import ExlyLoader from "ui/modules/ExlyLoader";
import { EXLY_COMMUNICATIONS__REQUEST_USER_CATEGORY } from "../../redux/actions";
import { useSelector, useDispatch } from "react-redux";
import Accordian from "./Accordian";
import EmptyListing from "common/Components/EmptyListing/EmptyListing";
import EmptyListIcon from "assets/vectors/infoModal/Ad_Leads.svg";

const CustomerUserList = () => {
  const dispatch = useDispatch();
  const categories = useSelector(
    (state) => state.communications.user_categories
  );
  const fetching_communications = useSelector(
    (state) => state.communications.fetching_communications
  );

  React.useEffect(() => {
    dispatch({
      type: EXLY_COMMUNICATIONS__REQUEST_USER_CATEGORY,
      payload: { forceUpdate: true },
    });
  }, []);

  return (
    <div className={`dm_font`}>
      {fetching_communications && <ExlyLoader />}

      {is_empty(categories) ? (
        <EmptyListing
          logo={<img src={EmptyListIcon} alt="empty listing" />}
          content_title="No communications to show!"
        />
      ) : (
        <Accordian type={constant.user_type} />
      )}
    </div>
  );
};

export default CustomerUserList;
