import React from "react";
import {
  List,
  Pagination,
  SimpleList,
  useListController,
  useRefresh,
} from "react-admin";

import { is_empty } from "../../../../../utils/validations";
import constants from "constants/constants";
import { draftEmailsProps } from "../resourceProps";

import { useStyles } from "./draftEmail.styles";
import { DraftsLoadingShimmer } from "../components";
import ExlyDeleteModal from "common/Components/ExlyDeleteModal/ExlyDeleteModal";
import EmailListItem from "../components/EmailListItem/EmailListItem";
import EmailCampaignLayout from "../layouts/EmailCampaignLayout";
import { tabValues } from "../data";
import withComponentLibraryTheme from "hocs/withComponentLibraryTheme";
import { RESOURCE_KEYS, orgPermissions } from "utils/OrgPermissions";
import { deleteEmail } from "../Email.helpers";
import { useNotifications } from "utils/hooks";
import CustomEmailEmptyState from "../components/CustomEmailEmptyState/CustomEmailEmptyState";

const DraftEmails = (props) => {
  const classes = useStyles();
  const { notify } = useNotifications();
  const refresh = useRefresh();

  const { data, loading } = useListController(draftEmailsProps);

  const hasWriteAccess = orgPermissions.hasFeatureWriteAccess(
    RESOURCE_KEYS.SECTIONS.CUSTOM_MAILS
  );

  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [selectedEmail, setSelectedEmail] = React.useState(null);

  const ListPagination = (props) => (
    <Pagination rowsPerPageOptions={[]} {...props} />
  );

  const handleDelete = (email) => {
    setSelectedEmail(email);
    setShowDeleteModal(true);
  };

  return (
    <EmailCampaignLayout selectedTab={tabValues.DRAFT_EMAIL} {...props}>
      {loading && <DraftsLoadingShimmer />}
      {!loading && is_empty(data) && (
        <CustomEmailEmptyState
          title="You have no drafts saved currently. Start drafting one."
          description="Send emails to your leads or customers. Share your upcoming events, publish a newsletter, provide an important update or just send a greeting"
          imgSrc="/assets/vectors/sales_and_marketing/broadcast/draft_illustration.svg"
        />
      )}

      {!is_empty(data) && !loading && (
        <List
          style={
            window.screen.width > constants.mobile_width
              ? {}
              : { width: "100%", padding: 0, overflow: "hidden" }
          }
          id={"past_emails_list"}
          {...draftEmailsProps}
          component={"div"}
          // resource={api.fetch_draft_emails}
          // basePath={api.fetch_draft_emails}
          exporter={false}
          perPage={50}
          pagination={<ListPagination />}
          actions={null}
        >
          <div>
            <SimpleList
              className={classes.simpleListRoot}
              rowStyle={() => ({ padding: 0, margin: "6px 0" })}
              primaryText={(email, id) => (
                <EmailListItem
                  id={id}
                  type="draft"
                  email={email}
                  key={email.uuid}
                  onDelete={() => handleDelete(email)}
                  hasWriteAccess={hasWriteAccess}
                />
              )}
              linkType={false}
            />
          </div>
        </List>
      )}

      <ExlyDeleteModal
        open={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        title="Are you sure?"
        description={`Deleting this email from draft emails. It will still be visible on your emailing plaftform.`}
        onDelete={() =>
          deleteEmail(selectedEmail, "draft", notify, () => {
            refresh();
            setShowDeleteModal(false);
          })
        }
      />
    </EmailCampaignLayout>
  );
};

export default withComponentLibraryTheme(DraftEmails);
