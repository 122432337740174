import React from "react";

import { Tooltip } from "@my-scoot/component-library-legacy";

import { useStyles } from "./DesktopRow.styles";

import DataItem from "../DataItem";

import { IconButton } from "@material-ui/core";

import CopyIcon from "assets/images/copy.svg";
import EmailIcon from "assets/images/email.svg";

import { EditRounded as EditIcon } from "@material-ui/icons";
import DeleteRoundedIcon from "@material-ui/icons/DeleteRounded";
import VisibilityIcon from "@material-ui/icons/Visibility";

import classnames from "classnames";
import {
  CAMPAIGN_DESCRIPTIONS,
  CAMPAIGN_TYPES,
  EMAIL_TYPES,
} from "ui/pages/communications/CustomEmail/data";
import { getStatusChip } from "../getStatusChip";
import { campaignStepsData } from "../../utils";
import InfoIcon from "@material-ui/icons/InfoOutlined";

const DesktopRow = ({
  alternate,
  email,
  recepients,
  type,
  subject,
  time,
  edit,
  onViewEditClick,
  onDuplicateClick,
  onDelete,
  hasWriteAccess,
}) => {
  const classes = useStyles({ type });

  const { campaign_step, campaign_type, added_by_name } = email;

  const getTimePrefix = () => {
    if (type === EMAIL_TYPES.SCHEDULED)
      return <span className={classes.scheduledPrefix}>Scheduled For</span>;
    if (type === EMAIL_TYPES.DRAFT) return "Edited";
    return campaign_type === CAMPAIGN_TYPES.NEW
      ? "Campaign start time:"
      : "Sent";
  };

  return (
    <div
      className={classnames(
        classes.root,
        alternate && classes.dark,
        !hasWriteAccess && "disable_pointer"
      )}
    >
      <img className={classes.emailIcon} src={EmailIcon} alt="email" />

      <div className={classes.content}>
        <div className={classes.header}>
          <div className={classes.leftContainer}>
            <div className={classes.mailTo}>
              <div className={classes.recepients} onClick={onViewEditClick}>
                To: {recepients}
              </div>

              {getStatusChip({ type, classes })}
            </div>

            <div className={classes.subject}>Subject: {subject}</div>

            {time ? (
              <div className={classes.dateTime}>
                {getTimePrefix()} {time}
              </div>
            ) : (
              <div className={classes.dateTime}>Campaign to be processed</div>
            )}
          </div>

          <div className={classes.rightContainer}>
            <div className={classes.iconContainer}>
              <Tooltip arrow color="primary" placement="top" title="View email">
                <IconButton
                  className={classes.iconButton}
                  onClick={onViewEditClick}
                >
                  {edit ? <EditIcon /> : <VisibilityIcon />}
                </IconButton>
              </Tooltip>
              <Tooltip
                arrow
                color="primary"
                placement="top"
                title="Duplicate this email"
              >
                <IconButton
                  className={classes.iconButton}
                  onClick={onDuplicateClick}
                >
                  <img className={classes.copyIcon} src={CopyIcon} alt="" />
                </IconButton>
              </Tooltip>
              <IconButton
                className={classnames(classes.iconButton, classes.deleteIcon)}
                onClick={onDelete}
              >
                <DeleteRoundedIcon />
              </IconButton>
            </div>

            {campaign_type === CAMPAIGN_TYPES.NEW &&
              type === EMAIL_TYPES.SCHEDULED && (
                <div className={classes.campaignDataContainer}>
                  <Tooltip
                    arrow
                    color="primary"
                    placement="top"
                    title={CAMPAIGN_DESCRIPTIONS[campaign_step]}
                  >
                    <InfoIcon />
                  </Tooltip>
                  <Tooltip
                    arrow
                    color="primary"
                    placement="top"
                    title={added_by_name ? `Added by ${added_by_name}` : ""}
                  >
                    <div
                      className={classnames(
                        classes.campaignStep,
                        classes[campaignStepsData[campaign_step].className]
                      )}
                    >
                      {campaignStepsData[campaign_step].label}
                    </div>
                  </Tooltip>
                </div>
              )}
          </div>
        </div>

        {type === EMAIL_TYPES.PAST && (
          <>
            <hr className={classes.divider} />

            <div className={classes.bottomDataContainer}>
              <div className={classes.dataContainer}>
                <DataItem
                  dataClassName={classes.sentTo}
                  title="Sent to"
                  data={email?.sent_to_count ?? 0}
                />
                <div className={classes.horizontalDivider} />
                <DataItem
                  dataClassName={classes.openedBy}
                  title="Opened by"
                  data={email?.opened_count ?? 0}
                />
                <div className={classes.horizontalDivider} />
                <DataItem
                  dataClassName={classes.openedRate}
                  title="Opened rate(%)"
                  data={(email?.open_rate_count ?? 0).toFixed(2)}
                />
              </div>

              {campaign_type === CAMPAIGN_TYPES.NEW && (
                <div className={classes.campaignDataContainer}>
                  <Tooltip
                    arrow
                    color="primary"
                    placement="top"
                    title={CAMPAIGN_DESCRIPTIONS[campaign_step]}
                  >
                    <InfoIcon />
                  </Tooltip>
                  <Tooltip
                    arrow
                    color="primary"
                    placement="top"
                    title={added_by_name ? `Added by ${added_by_name}` : ""}
                  >
                    <div
                      className={classnames(
                        classes.campaignStep,
                        classes?.[campaignStepsData?.[campaign_step]?.className]
                      )}
                    >
                      {campaignStepsData?.[campaign_step]?.label}
                    </div>
                  </Tooltip>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default DesktopRow;
