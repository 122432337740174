export const RECIPIENT_KEYS = {
  uuid: "uuid",
  key: "key",
  display_name: "display_name",
  user_count: "user_count",
  section_type: "section_type",
  audience_type: "audience_type",
  listing_type: "listing_type",
  sub_sections: "sub_sections",
  sub_section_type: "sub_section_type",
  user_type: "user_type",
  payment_plan_type: "payment_plan_type",
};

export const SEGMENT_KEYS = {
  email_count: "email_count",
  segment_uuid: "segment_uuid",
  segment_type: "segment_type",
};
