import React from "react";
import PollOutlinedIcon from "@material-ui/icons/PollOutlined";
import RemoveCircleOutlineOutlinedIcon from "@material-ui/icons/RemoveCircleOutlineOutlined";

import { ReactComponent as CopyIcon } from "assets/images/copy.svg";
import dripModuleStyle from "../ActiveDrip/ActiveDrip.module.css";
import { RESOURCE_KEYS, orgPermissions } from "utils/OrgPermissions";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import useDripActions from "../ActiveDrip/useDripActions";

const useInactiveDripActions = ({
  selectedDrip,
  setSelectedDrip,
  closeMobileOptions,
  openDeleteModal,
}) => {
  const isDesktop = useDesktopMediaQuery();
  const writeDisabled = !orgPermissions.hasFeatureWriteAccess(
    RESOURCE_KEYS.SECTIONS.CUSTOM_MAILS
  );

  const { handleDuplication, handleEdit, handleViewAnalyticsPerEmail } =
    useDripActions({ selectedDrip });

  function handleDeleteDrip(record) {
    if (isDesktop) setSelectedDrip(record);
    closeMobileOptions();
    openDeleteModal();
  }

  const actions = [
    {
      icon: (
        <PollOutlinedIcon
          className={
            isDesktop
              ? dripModuleStyle.desktopActionIcon
              : dripModuleStyle.actionIcon
          }
        />
      ),
      onClick: handleViewAnalyticsPerEmail,
      label: "View analytics per email",
      className: dripModuleStyle.blackButton,
    },
    {
      icon: (
        <CopyIcon
          className={
            isDesktop
              ? dripModuleStyle.desktopActionIcon
              : dripModuleStyle.actionIcon
          }
        />
      ),
      onClick: handleDuplication,
      label: "Duplicate drip",
      disabled: writeDisabled,
      className: dripModuleStyle.blackButton,
    },
    {
      icon: (
        <RemoveCircleOutlineOutlinedIcon
          className={
            isDesktop
              ? dripModuleStyle.desktopActionIcon
              : dripModuleStyle.redAction
          }
        />
      ),
      onClick: handleDeleteDrip,
      label: "Delete drip",
      disabled: writeDisabled,
      className: dripModuleStyle.redButton,
    },
  ];

  return {
    writeDisabled,
    handleEdit,
    actions,
  };
};

export default useInactiveDripActions;
