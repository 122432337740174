import { useState } from "react";
import { useHistory } from "react-router-dom";
import qs from "query-string";
import { useRefresh } from "react-admin";

import { RESOURCE_KEYS, orgPermissions } from "utils/OrgPermissions";
import { duplicateEmailDrip } from "ui/pages/EmailDrip/EmailDrip.ApiCalls";
import { notification_color_keys, useNotifications } from "utils/hooks";
import {
  app_pathnames,
  app_route_ids,
  app_route_keys,
} from "constants/urlPaths";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import { EMAIL_DRIP_STATUS } from "ui/pages/EmailDrip/EmailDrip.constants";

const useDripActions = ({ selectedDrip, isDraftListing }) => {
  const writeDisabled = !orgPermissions.hasFeatureWriteAccess(
    RESOURCE_KEYS.SECTIONS.CUSTOM_MAILS
  );
  const [duplicating, setDuplicating] = useState(false);
  const { notify } = useNotifications();
  const history = useHistory();
  const isDesktop = useDesktopMediaQuery();
  const refresh = useRefresh();

  function handleEdit(record) {
    if (writeDisabled) return;
    const currentRecord = selectedDrip || record;
    const dripUuid = currentRecord?.uuid;
    const showWarningModal =
      currentRecord?.status === EMAIL_DRIP_STATUS.ACTIVE ||
      currentRecord?.status === EMAIL_DRIP_STATUS.PAUSED;

    const query = { "edit-warn": true };
    const searchString = qs.stringify(query);

    history.push({
      pathname: app_pathnames[app_route_keys.email_drip_setup](dripUuid),
      search: showWarningModal ? searchString : "",
    });
  }

  function handleViewAnalyticsPerEmail(record) {
    const currentRecord = isDesktop ? record : selectedDrip;
    const dripUuid = currentRecord?.uuid;
    history.push(app_pathnames[app_route_keys.email_drip_analytics](dripUuid));
  }

  async function handleDuplication(record) {
    if (duplicating || writeDisabled) return;

    setDuplicating(true);
    const currentRecord = isDesktop ? record : selectedDrip;
    const dripUuid = currentRecord?.uuid;

    try {
      await duplicateEmailDrip(dripUuid);
      notify("Copy of drip added to drafts", notification_color_keys.success);
      if (isDraftListing) {
        refresh();
      } else {
        history.push(`/${app_route_ids[app_route_keys.draft_drip]}`);
      }
    } catch (err) {
      console.log(err, "Error duplicating drip");
      notify(
        "Failed to duplicate, please try again.",
        notification_color_keys.error
      );
    } finally {
      setDuplicating(false);
    }
  }

  return { handleDuplication, handleEdit, handleViewAnalyticsPerEmail };
};

export default useDripActions;
